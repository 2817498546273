import React from 'react';

import { bemBlock } from '../../modules/bem';
import { Text } from '../text/Text';

import './HeaderTitle.less';

const block = bemBlock('header-title');

export const HeaderTitle = (props: React.ComponentProps<typeof Text>) => {
  const { className, ...restProps } = props;
  return <Text fontWeight="bold" ellipsis className={block({ extra: className })} {...restProps} />;
};
