import React from 'react';
import { noop } from 'lodash';

import { LayoutColumn } from '../layout-column/LayoutColumn';
import { LayoutRow } from '../layout-row/LayoutRow';

import { NavigationTabsContext } from './NavigationTabsContext';
import { NavigationTabsItem } from './NavigationTabsItem';

type NavigationTabsProps = {
  children: React.ReactNode;
  activeTabId?: string;
  startOutlet?: React.ReactNode;
  onChange?: (tabName: string) => void;
  'data-role'?: string;
};

const NavigationTabsRaw: React.FC<NavigationTabsProps> = ({
  children,
  activeTabId = '',
  startOutlet,
  onChange = noop,
  'data-role': dataRole,
}) => {
  const tabsContext = React.useMemo(() => ({ activeTabId, onChange }), [activeTabId, onChange]);
  return (
    <LayoutColumn data-role={dataRole}>
      <NavigationTabsContext.Provider value={tabsContext}>
        <LayoutRow spacedChildren="sm" alignItems="center">
          {startOutlet}

          <LayoutRow span="shrink">{children}</LayoutRow>
        </LayoutRow>
      </NavigationTabsContext.Provider>
    </LayoutColumn>
  );
};

export const NavigationTabs = Object.assign(NavigationTabsRaw, {
  Item: NavigationTabsItem,
});
