// Libs
import { createSelector } from 'reselect';

import { naturalStringComparator } from '@neptune/shared/common-util';

import { Dashboard } from 'domain/dashboard';
import { fetchStatus } from 'state/fetch-status';
// App
import { AppState } from 'state/types';

// Module
import getState from '../selectors';

import { DashboardsState } from './types';

function getProjectDashboardsRaw(state: AppState): DashboardsState {
  return getState(state).projectDashboards;
}

export const getProjectDashboardFetchStatus = (state: AppState): fetchStatus =>
  getProjectDashboardsRaw(state).fetchStatus;

export const getProjectDashboards = createSelector([getProjectDashboardsRaw], (dashboardsState) => {
  const dashboards = dashboardsState.dashboards.sort((a, b) =>
    naturalStringComparator(a.name, b.name),
  );

  return {
    ...dashboardsState,
    dashboards,
  };
});

export const getDashboards = (state: AppState) => getProjectDashboards(state).dashboards;

export const getDashboard = createSelector(
  [getDashboards, (state: AppState, branchId: string) => branchId],
  (compareDashboards, branchId) => {
    return compareDashboards.find(({ versionBranchId }) => versionBranchId === branchId);
  },
);

export const getDefaultDashboard = (state: AppState): Dashboard | null =>
  getDashboards(state)[0] ?? null;
