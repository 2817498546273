import { Dictionary, keyBy } from 'lodash';

import { backendClient, ListProjectsRequest } from '@neptune/shared/core-apis-backend-domain';
import { leaderboardClient } from '@neptune/shared/core-apis-leaderboard-domain';
import {
  ProjectWithRole,
  projectWithRoleFromApiToDomain,
} from '@neptune/shared/core-project-domain';

export type ProjectTab = 'runs' | 'models' | 'metadata' | 'trash' | 'reports';

export type GetProjectsForOrganizationParams = {
  organizationIdentifier: string;
  searchTerm?: string;
  sortBy?: string[];
  sortDirection?: string[];
  offset?: number;
  limit?: number;
  archived?: boolean;
};

export type GetProjectsParams = ListProjectsRequest;

export async function getProjects(params: GetProjectsParams = {}): Promise<{
  projects: ProjectWithRole[];
  hasMoreProjects: boolean;
}> {
  const { entries, totalItemCount } = await backendClient.listProjects(params);

  return {
    projects: entries.map(projectWithRoleFromApiToDomain),
    hasMoreProjects: totalItemCount !== entries.length,
  };
}

export async function getProjectsForOrganization(
  params: GetProjectsForOrganizationParams,
): Promise<{ projects: ProjectWithRole[]; hasMoreProjects: boolean }> {
  const { entries, totalItemCount } = await backendClient.listOrganizationProjects(params);

  return {
    projects: entries.map(projectWithRoleFromApiToDomain),
    hasMoreProjects: totalItemCount !== entries.length,
  };
}

export type ProjectSidebarData = Pick<
  ProjectWithRole,
  'avatarUrl' | 'name' | 'organizationName' | 'timeOfCreation' | 'avatarSource'
>;

export type ProjectStats = {
  leaderboardEntryCount?: number;
};

export async function getProjectsStats(
  projectIdentifiers: string[],
): Promise<Dictionary<ProjectStats> | undefined> {
  try {
    const trackingData = await leaderboardClient.listTrackingData({ projectIdentifiers });
    const projectStats = trackingData.map((data) => ({
      identifier: data.identifier,
      leaderboardEntryCount: data.experiments?.count,
    }));
    return keyBy<ProjectStats & { identifier?: string }>(
      projectStats,
      (data) => data.identifier ?? 'undefined',
    );
  } catch {
    return undefined;
  }
}
