import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Entity } from '@neptune/shared/entity-domain';

import { fetchBasicCurrentEntity } from './redux/actions';
import {
  getCurrentBasicEntity,
  getCurrentBasicEntityFetchError,
  getCurrentBasicEntityFetchStatus,
} from './redux/selectors';

export const useBasicEntityDetails = (
  organizationName: string,
  projectName: string,
  runIdentificationKey?: string,
) => {
  const dispatch = useDispatch();

  const entity: Entity | undefined = useSelector(getCurrentBasicEntity);
  const fetchStatus = useSelector(getCurrentBasicEntityFetchStatus);
  const error = useSelector(getCurrentBasicEntityFetchError);

  const fetchEntity = React.useCallback(() => {
    if (!runIdentificationKey) {
      return;
    }

    dispatch(fetchBasicCurrentEntity({ organizationName, projectName, runIdentificationKey }));
  }, [dispatch, organizationName, projectName, runIdentificationKey]);

  return {
    entity,
    error,
    fetchEntity,
    fetchStatus,
  };
};

export const useBasicEntityDetailsWithFetching = (
  organizationName: string,
  projectName: string,
  runIdentificationKey?: string,
) => {
  const result = useBasicEntityDetails(organizationName, projectName, runIdentificationKey);
  const { fetchEntity } = result;

  React.useEffect(fetchEntity, [fetchEntity]);

  return result;
};
