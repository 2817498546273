import remarkGfm from 'remark-gfm';
import remarkParse from 'remark-parse';
import { Processor, unified } from 'unified';

type MDASTNode = {
  type: string;
  alt?: string;
  value?: string;
  children?: MDASTNode[];
};

function toString(value: MDASTNode) {
  return processChild(value);
}

function processChild(value: MDASTNode): string {
  if (node(value)) {
    if ('value' in value) {
      return value.type === 'html' ? '' : value.value ?? '';
    }

    if ('alt' in value && value.alt) {
      return value.alt;
    }

    if ('children' in value && value.children) {
      return processChildren(value.children);
    }
  }

  if (Array.isArray(value)) {
    return processChildren(value);
  }

  return '';
}

function processChildren(values: MDASTNode[]): string {
  const result = [];
  let index = -1;

  while (++index < values.length) {
    result[index] = processChild(values[index]);
  }

  return result.join(' ');
}

function node(value: MDASTNode) {
  return Boolean(value && typeof value === 'object');
}

function stringify(this: Processor) {
  this.compiler = function (tree): string {
    return toString(tree);
  };
}

function markdownToPlainText(markdown: string) {
  const result = unified().use(remarkParse).use(remarkGfm).use(stringify).processSync(markdown);

  return String(result).trim();
}

export function getTitleFromMarkdown(text?: string) {
  if (!text) {
    return 'Empty markdown widget';
  }

  const plainText = markdownToPlainText(text);

  if (!plainText.length) {
    return 'Empty markdown widget';
  }

  const words = plainText.split(/\s+/);

  if (words.length <= 5) {
    return words.join(' ');
  }

  return words.slice(0, 5).join(' ') + '...';
}
