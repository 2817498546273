import { compact, isEmpty, uniq } from 'lodash';

import { AttributeNameFilter } from '@neptune/shared/search-domain';

export const updateAttributeNameFilter = (
  filter: AttributeNameFilter | undefined,
  update: AttributeNameFilter | undefined,
): AttributeNameFilter | undefined => {
  if (isNestedFilter(filter)) {
    return {
      mustMatchAny: compact(
        filter.mustMatchAny.map((subFilter) => updateSingleAttributeNameFilter(subFilter, update)),
      ),
    };
  }

  return updateSingleAttributeNameFilter(filter, update);
};

const isNestedFilter = (
  filter: AttributeNameFilter | undefined,
): filter is Required<Pick<AttributeNameFilter, 'mustMatchAny'>> =>
  Boolean(filter && filter.mustMatchAny && !isEmpty(filter.mustMatchAny));

const updateSingleAttributeNameFilter = (
  filter: AttributeNameFilter | undefined,
  update: AttributeNameFilter | undefined,
): AttributeNameFilter | undefined => {
  const newMustMatchRegexes = uniq([
    ...(filter?.mustMatchRegexes ?? []),
    ...(update?.mustMatchRegexes ?? []),
  ]);
  const newMustNotMatchRegexes = uniq([
    ...(filter?.mustNotMatchRegexes ?? []),
    ...(update?.mustNotMatchRegexes ?? []),
  ]);

  if (newMustMatchRegexes.length === 0 && newMustNotMatchRegexes.length === 0) {
    return undefined;
  }

  return {
    mustMatchRegexes: newMustMatchRegexes.length > 0 ? newMustMatchRegexes : undefined,
    mustNotMatchRegexes: newMustNotMatchRegexes.length > 0 ? newMustNotMatchRegexes : undefined,
  };
};
