import React from 'react';
import { useQueries, useQueryClient } from '@tanstack/react-query';
import { chunk } from 'lodash';

import { mapMergeUseQueriesResults, useMemoizedValue } from '@neptune/shared/common-util';
import { Entity } from '@neptune/shared/entity-domain';
import { searchLeaderboard } from '@neptune/shared/leaderboard-domain';

import {
  KnownAttributes,
  knownAttributesForExperimentIdentification,
} from 'domain/experiment/attribute';

const MAX_IDS_PER_REQUEST = 1000;
const EMPTY_SELECTION: Entity[] = [];

export function useEntityIdentificationEntries(experimentNamesByProject: Map<string, string[]>) {
  const queryClient = useQueryClient();

  const results = useQueries({
    queries: Array.from(
      experimentNamesByProject.entries(),
      ([projectIdentifier, experimentNames]) => ({
        cacheTime: 10_000,
        keepPreviousData: true,
        enabled: experimentNames.length !== 0,
        queryFn: async (): Promise<[string, Entity[]]> => [
          projectIdentifier,
          await queryFieldsFromAllPages(projectIdentifier, experimentNames),
        ],
        queryKey: makeQueryKey(projectIdentifier, experimentNames),
      }),
    ),
  });

  const invalidateQuery = React.useCallback(
    (projectId?: string) => {
      if (projectId) {
        queryClient.invalidateQueries({ queryKey: makeQueryKey(projectId) });
      } else {
        experimentNamesByProject.forEach((_, projectIdentifier) => {
          queryClient.invalidateQueries({ queryKey: makeQueryKey(projectIdentifier) });
        });
      }
    },
    [experimentNamesByProject, queryClient],
  );

  // useQueries return value is not stable (only the [*].data fields are),
  // so useMemo would be pointless here, unless used with dynamic deps array.
  const result = useMemoizedValue(mapMergeUseQueriesResults(results));

  return { result, invalidateQuery };
}

function makeQueryKey(projectIdentifier: string, experimentNames?: string[]) {
  return [
    'entity-identification-map',
    projectIdentifier,
    ...(experimentNames ? [{ experimentNames }] : []),
  ];
}

async function queryFieldsFromAllPages(projectIdentifier: string, names: string[]) {
  if (!names.length) {
    return Promise.resolve(EMPTY_SELECTION);
  }

  const namesQuery = names.map(
    (name) =>
      `((${KnownAttributes.Name}:string = "${name}") AND (${KnownAttributes.ExperimentIsHead}:bool = true))`,
  );

  return (
    await Promise.all(
      chunk(namesQuery, MAX_IDS_PER_REQUEST).map(async (chunkIds) => {
        const leaderboard = await searchLeaderboard({
          attributesToFetch: [...knownAttributesForExperimentIdentification],
          pagination: { limit: MAX_IDS_PER_REQUEST, offset: 0 },
          query: chunkIds.join(' OR '),
          experimentsOnly: true,
          projectIdentifier,
          type: ['run'],
          sorting: {
            dir: 'ascending',
            sortBy: {
              type: 'datetime',
              aggregationMode: 'auto',
              name: KnownAttributes.CreationTime,
            },
          },
        });

        return leaderboard.entries;
      }),
    )
  ).flat();
}
