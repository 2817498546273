// Libs
import React from 'react';
import ReactMarkdown from 'react-markdown';

import { bemBlock } from '@neptune/shared/venus-ui';

// Module
import defaultRenderers from './renderers';

import 'github-markdown-css/github-markdown.css';
import './MarkdownRenderer.less';

export type MarkdownRendererProps = {
  remarkPlugins?: React.ComponentProps<typeof ReactMarkdown>['remarkPlugins'];
  renderers?: React.ComponentProps<typeof ReactMarkdown>['components'];
  className?: string;
  content?: string;
};

const block = bemBlock('markdown-renderer');

export const MarkdownRenderer = ({
  content,
  className,
  remarkPlugins,
  renderers = defaultRenderers,
}: MarkdownRendererProps) => {
  const githubCssClassName = 'markdown-body';

  return (
    <ReactMarkdown
      className={block({ extra: [githubCssClassName, className] })}
      remarkPlugins={remarkPlugins}
      components={renderers}
    >
      {content}
    </ReactMarkdown>
  );
};
