import { SearchQueryTerm } from './types';

export const fromTermsToIncludedExcludedCriterions = (terms: SearchQueryTerm[]) => {
  const { include, exclude } = terms.reduce<{
    include: string[];
    exclude: string[];
    isExclude: boolean;
  }>(
    (acc, term) => {
      if (term.type === 'OPERATOR') {
        return { ...acc, isExclude: term.value.includes('NOT') };
      }

      if (term.type === 'CRITERION' && acc.isExclude) {
        return { ...acc, exclude: [...acc.exclude, term.value] };
      }

      if (term.type === 'CRITERION') {
        return { ...acc, include: [...acc.include, term.value] };
      }

      return acc;
    },
    { include: [], exclude: [], isExclude: false },
  );

  return {
    mustMatchRegexes: include,
    mustNotMatchRegexes: exclude,
  };
};
