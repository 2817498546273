import React from 'react';
import ReactModal from 'react-modal';

import { bemBlock } from '../../modules/bem';
import { Card } from '../card/Card';

import ModalBody from './ModalBody';
import ModalButton from './ModalButton';
import ModalClose from './ModalClose';
import ModalFooter from './ModalFooter';
import ModalFooterNew from './ModalFooterNew';
import ModalHeader from './ModalHeader';
import ModalText from './ModalText';

import './Modal.less';

export type ModalProps = ReactModal.Props & {
  children?: React.ReactNode;
  className?: string;
  contentClassName?: string;
  data?: { [key: string]: string | undefined };
  variant?: 'full' | 'simple';
  size?: 'sm' | 'md' | 'lg' | 'adaptive';
  onClose?: () => void;
  shouldCloseOnEsc?: boolean;
};

const block = bemBlock('n-modal');

function ModalComponent({
  children,
  className,
  contentClassName,
  isOpen = false,
  variant = 'full',
  size = 'md',
  onClose,
  shouldCloseOnEsc = true,
  ...restProps
}: ModalProps) {
  React.useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (!shouldCloseOnEsc || event.key !== 'Escape') {
        return;
      }

      onClose?.();
      event.stopPropagation();
    };

    if (isOpen) {
      document.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [isOpen, onClose, shouldCloseOnEsc]);

  // Will prevent rendering ReactModalPortal
  // this will assure, that modals are displayed in order they are opened
  if (!isOpen) {
    return null;
  }

  const cssClass = block({
    extra: [className],
    modifiers: {
      simple: variant === 'simple',
      size,
    },
  });

  return (
    <ReactModal
      className={{
        base: cssClass,
        afterOpen: block({ modifiers: ['after-open'] }),
        beforeClose: block({ modifiers: ['before-close'] }),
      }}
      isOpen={isOpen}
      overlayClassName={{
        base: block('overlay'),
        afterOpen: block({ element: 'overlay', modifiers: ['after-open'] }),
        beforeClose: block({ element: 'overlay', modifiers: ['before-close'] }),
      }}
      contentLabel="neptune-modal" // even though we don't use aria, this prop is required
      ariaHideApp={false}
      closeTimeoutMS={150}
      onRequestClose={onClose}
      {...restProps}
    >
      <Card className={block({ element: 'card', extra: contentClassName })} span="shrink">
        {children}
      </Card>
    </ReactModal>
  );
}

export const Modal = Object.assign(ModalComponent, {
  Body: ModalBody,
  Button: ModalButton,
  Close: ModalClose,
  Footer: ModalFooter,
  FooterNew: ModalFooterNew,
  Header: ModalHeader,
  Text: ModalText,
});
