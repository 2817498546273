import { createSelector } from 'reselect';

import { CompareTab, RunDetailsTab } from '@neptune/project-runs-domain';

import { getDashboards } from 'state/project-dashboards/selectors';
import { AppState } from 'state/types';

export const getDashboardTabs = createSelector(
  [getDashboards, (state: AppState, type: 'dash' | 'details') => type],
  (dashboards, type) => {
    if (type === 'dash') {
      return dashboards.map<RunDetailsTab>((dashboard) => ({
        type: 'experiment',
        // we treat branchId as dashboardId to ensure backward compatibility
        dashboardId: dashboard.versionBranchId,
        routeParams: {
          detailsTab: 'dashboard',
          dashboardId: dashboard.versionBranchId,
        },
        label: dashboard.name,
      }));
    }

    return dashboards.map<CompareTab>((dashboard) => {
      return {
        // we treat branchId as dashboardId to ensure backward compatibility
        type: 'compare',
        dashboardId: dashboard.versionBranchId,
        routeParams: {
          dash: 'dashboard',
          dashboardId: dashboard.versionBranchId,
        },
        label: dashboard.name,
      };
    });
  },
);
