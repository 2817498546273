import React from 'react';

export const useOnKeyDown = (
  callback: (event: KeyboardEvent) => void,
  options?: boolean | EventListenerOptions,
) => {
  React.useEffect(() => {
    window.addEventListener('keydown', callback, options);
    return () => window.removeEventListener('keydown', callback, options);
  }, [callback, options]);
};
