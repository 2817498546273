import React from 'react';

import { bemBlock, Layout, Modal, Text } from '@neptune/shared/venus-ui';

import image from './server-error.svg';

import './ServerErrorModal.less';

type ServerErrorModal = {
  isOpen: boolean;
  onClose: () => void;
};

const block = bemBlock('server-error-modal');

export function ServerErrorModal({ isOpen, onClose }: ServerErrorModal) {
  return (
    <Modal className={block()} isOpen={isOpen} variant="simple" onClose={onClose}>
      <Modal.Header onClose={onClose} />
      <Modal.Body>
        <Layout.Column spacedChildren="sm">
          <Layout.Row>
            <img className={block('image')} src={image} />
          </Layout.Row>
          <Layout.Row>
            <Text color="error" fontWeight="semibold" size="lg">
              Oops! The server didn’t respond.
            </Text>
          </Layout.Row>
          <Layout.Row>
            <Text size="sm">Please try again in a few seconds.</Text>
          </Layout.Row>
        </Layout.Column>
      </Modal.Body>
    </Modal>
  );
}
