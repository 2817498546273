import { makeProjectIdentifier } from '@neptune/shared/core-project-util';
import { Entity, fetchEntityChunk, getEntityShortId } from '@neptune/shared/entity-domain';
import { searchLeaderboard } from '@neptune/shared/leaderboard-domain';

import { createSelectionQuery } from 'common/query';

// TODO: Move to @neptune/shared/entity-domain.
// This code is here temporarily, as the temporary implementation needs to use searchLeaderboard.
// Importing searchLeaderboard into entity-domain caused circular dependency, so I needed
// to bump it a layer up.

export async function fetchEntitiesChunks({
  entities,
  fieldsToFetch,
}: {
  entities: Entity[];
  fieldsToFetch: string[];
}): Promise<(Entity | undefined)[]> {
  // The code below is much complicated because we are missing the new endpoint
  // for fetching attributes for multiple entities, and we need to use
  // a leaderboard-based plug for that case.
  if (entities.length === 1) {
    const onlyEntity = entities[0];

    const result = await fetchEntityChunk({
      id: onlyEntity.id,
      type: onlyEntity.type,
      fieldsToFetch,
    });

    return [result];
  }

  const sampleEntity = entities[0];
  const projectIdentifier = makeProjectIdentifier(
    sampleEntity.organizationName,
    sampleEntity.projectName,
  );

  // For multiple runs/entities we need to use ES endpoint, as there is no other implementation yet.
  // When dedicated endpoint comes in, it will likely have a different signature, with full ids
  // instead of project + shortids.
  const fetchedEntities = await temporarySearchLeaderboardImpl({
    projectIdentifier,
    entitiesShortIds: entities.map((entity) => getEntityShortId(entity)),
    fieldsToFetch,
  });

  const map = new Map(fetchedEntities.map((entity) => [entity.id, entity]));
  return entities.map((entity) => map.get(entity.id));
}

// This is a temporary plug to be eventually replaced
// when multi-entity version of getAttributesWithPathsFilter endpoint
// rolls out.
async function temporarySearchLeaderboardImpl({
  projectIdentifier,
  entitiesShortIds,
  fieldsToFetch,
}: {
  projectIdentifier: string;
  entitiesShortIds: string[];
  fieldsToFetch: string[];
}) {
  const leaderboard = await searchLeaderboard({
    projectIdentifier,
    attributesToFetch: fieldsToFetch,
    query: createSelectionQuery(entitiesShortIds),
  });

  return leaderboard.entries;
}
