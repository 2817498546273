// Libs
import React from 'react';
import { debounce } from 'lodash';

const DEBOUNCE_WAIT = 50;

export function useResizeDetector<Element extends HTMLElement>(): {
  ref: React.Ref<Element>;
  element: Element | null;
  width?: number;
  height?: number;
} {
  const [width, setWidth] = React.useState<number | undefined>();
  const [height, setHeight] = React.useState<number | undefined>();
  const ref = React.useRef<Element | null>(null);
  const resizeObserverRef = React.useRef<ResizeObserver>();

  React.useLayoutEffect(() => {
    const resizeObserver =
      resizeObserverRef.current ??
      (resizeObserverRef.current = new ResizeObserver(
        debounce((entries: ResizeObserverEntry[]) => {
          setWidth((entries[0].target as HTMLElement).offsetWidth);
          setHeight((entries[0].target as HTMLElement).offsetHeight);
        }, DEBOUNCE_WAIT),
      ));

    if (ref.current) {
      setWidth(ref.current.offsetWidth);
      setHeight(ref.current.offsetHeight);

      resizeObserver.observe(ref.current);
    }

    return () => resizeObserver.disconnect();
  }, []);

  const innerRef: React.Ref<Element> = React.useCallback((node: Element | null) => {
    const resizeObserver = resizeObserverRef.current;

    if (resizeObserver) {
      if (node) {
        resizeObserver.observe(node);
      } else {
        resizeObserver.disconnect();
      }
    }

    ref.current = node;
  }, []);

  return {
    ref: innerRef,
    element: ref.current,
    width,
    height,
  };
}
