import { useSelector } from 'react-redux';

import { getCurrentBasicEntity } from '@neptune/current-entity-business-logic';
import { getEntityName } from '@neptune/shared/entity-domain';
import { getEntityDisplayName } from '@neptune/shared/entity-util';
import { createCurrentRouteParamSelector } from '@neptune/shared/routing-business-logic';

import { AppState } from 'state/types';

import config from 'config';

const runIdentificationKeySelector = createCurrentRouteParamSelector('runIdentificationKey');

const getCurrentRunName = (state: AppState): string | undefined => {
  const runIdentificationKey = runIdentificationKeySelector(state);

  if (config.primaryColumn === 'id') {
    return runIdentificationKey;
  }

  const currentBasicEntity = getCurrentBasicEntity(state);

  if (!currentBasicEntity) {
    return undefined;
  }

  return getEntityDisplayName(runIdentificationKey, getEntityName(currentBasicEntity));
};

export const useCurrentRunIdentity = () => {
  const currentRunName = useSelector(getCurrentRunName);
  const currentRunIdentificationKey = useSelector(runIdentificationKeySelector);

  return {
    currentRunName,
    currentRunIdentificationKey,
  };
};
