// Utils for reconciling values of objects with previous values to
// keep as much of them stable.

import { isEqual, keyBy } from 'lodash';

// Determine keys for values that are not primitives and should be reconciled.
export type FilterObjectFieldsKeys<Type extends object, Key extends keyof Type> = Type[Key] extends
  | object
  | undefined
  ? Key
  : never;

// Using trick from https://github.com/microsoft/TypeScript/issues/31025#issuecomment-484734942
// to get rid of optionality but keep undefined in the union type. This way we can make fields required,
// but still accept all valid values.
// FilterObjectFieldsKeys takes care of filtering out keys with primitive values that don't need
// reconciliation.
export type FilterObjectFields<Type extends object> = {
  [Key in keyof Type & keyof any as FilterObjectFieldsKeys<Type, Key>]: Type[Key];
};

export function reconcileValue<T>(value: T, previousValue: T): T {
  return isEqual(value, previousValue) ? previousValue : value;
}

export function reconcileArrayWithId<T extends { id: string }>(items: T[], oldItems?: T[]): T[];
export function reconcileArrayWithId<T extends { id: string }>(
  items?: T[],
  oldItems?: T[],
): T[] | undefined;

export function reconcileArrayWithId<T extends { id: string }>(
  items?: T[],
  oldItems?: T[],
): T[] | undefined {
  if (!items || !oldItems || items === oldItems) {
    return items;
  }

  const oldValuesMap: Partial<Record<string, T>> = keyBy(oldItems, 'id');
  // mismatch tracks whether both arrays are equal. For the arrays to be equal,
  // they have to have same length, and they must match on each index (i.e. it's
  // not enough to find identical item, it must be at the same position).
  let anyMismatch = oldItems.length !== items.length;

  const merged = items.map((item, i) => {
    const oldValue = oldValuesMap[item.id];
    const sameValue = oldValue && isEqual(oldValue, item);

    if (!sameValue || oldItems[i] !== oldValue) {
      anyMismatch = true;
    }

    return sameValue ? oldValue : item;
  });

  return anyMismatch ? merged : oldItems;
}

export function reconcileMap<T extends Map<any, any>>(
  map: T,
  oldMap?: T,
  reconcileFn?: (value: T, previousValue: T) => T,
): T;
export function reconcileMap<T extends Map<any, any>>(
  map?: T,
  oldMap?: T,
  reconcileFn?: (value: T, previousValue: T) => T,
): T | undefined;

export function reconcileMap<T extends Map<any, any>>(
  map?: T,
  oldMap?: T,
  reconcileFn = reconcileValue<T>,
): T | undefined {
  if (!map || !oldMap || oldMap === map) {
    return map;
  }

  const reconciled: T = new Map() as any; // TS doesn't understand that this assignment is OK.
  let oldDiff = oldMap.size !== map.size;
  let newDiff = false;

  for (const [k, v] of map) {
    const oldValue = oldMap.get(k);
    const reconciledV = oldValue !== undefined ? reconcileFn(v, oldValue) : v;

    oldDiff ||= !oldMap.has(k) || reconciledV !== oldValue;
    newDiff ||= reconciledV !== v;

    reconciled.set(k, reconciledV);
  }

  if (!oldDiff) {
    return oldMap;
  }

  if (!newDiff) {
    return map;
  }

  return reconciled;
}
