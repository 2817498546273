import React from 'react';

import { bemBlock } from '../../modules/bem';
import { HeaderTitle } from '../header-title/HeaderTitle';
import { LayoutColumn, LayoutColumnProps } from '../layout-column/LayoutColumn';

import './Header.less';

type HeaderProps = {} & LayoutColumnProps<{}>;

const block = bemBlock('header');

export const Header = (props: HeaderProps) => {
  const { className, ...restProps } = props;

  return <LayoutColumn span="shrink" className={block({ extra: className })} {...restProps} />;
};

Header.Title = HeaderTitle;
