import { RecentSearch, SearchQueryTerm } from './types';

const MAX_SHOWN_RECENT_SEARCH_RESULTS = 5;

function isTermIncluded(term: SearchQueryTerm, recentSearchTerm: SearchQueryTerm): boolean {
  return recentSearchTerm.value.includes(term.value) && recentSearchTerm.type === term.type;
}

function termMatches(term: SearchQueryTerm, recentSearch: RecentSearch): boolean {
  return recentSearch.terms.some((recentSearchTerm) => isTermIncluded(term, recentSearchTerm));
}

function allTermsMatch(currentTerm: SearchQueryTerm[], recentSearch: RecentSearch): boolean {
  return currentTerm.every((term) => termMatches(term, recentSearch));
}

export function findSearchesMatchingTerms(
  currentTerm: SearchQueryTerm[],
  recentSearches: RecentSearch[],
): RecentSearch[] {
  return recentSearches
    .reduce((matchingSearches: RecentSearch[], recentSearch) => {
      if (allTermsMatch(currentTerm, recentSearch)) {
        return [...matchingSearches, recentSearch];
      }

      return matchingSearches;
    }, [])
    .slice(0, MAX_SHOWN_RECENT_SEARCH_RESULTS);
}
