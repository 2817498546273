import React from 'react';
import { useDispatch } from 'react-redux';
import { noop } from 'lodash';
import { auditTime, delay, map } from 'rxjs';

import {
  getEntitiesNotificationStream,
  MetadataChangedNotification,
} from '@neptune/shared/entity-business-logic';
import { EntityType } from '@neptune/shared/entity-domain';
import { applyPauseWhenNotUnderEdit } from '@neptune/shared/venus-business-logic';
import { UnderEditContext } from '@neptune/shared/venus-domain';

import { updateBasicCurrentEntity } from './redux/actions';

type UseEntityUpdatesParams = {
  projectId?: string;
  entityId?: string;
  entityType: EntityType;
};

export function useBasicEntityUpdates(
  { projectId, entityId, entityType }: UseEntityUpdatesParams,
  callback: () => void = noop,
) {
  const dispatch = useDispatch();

  const { underEdit$ } = React.useContext(UnderEditContext);

  React.useEffect(() => {
    if (!entityId || !projectId) {
      return;
    }

    const syntheticNotification: MetadataChangedNotification = {
      messageType: 'MetadataChanged',
      messageBody: { id: entityId, type: entityType },
    };

    const updateEntityFromNotification = (notification: MetadataChangedNotification) => {
      callback();

      return updateBasicCurrentEntity({
        id: notification.messageBody.id,
        type: entityType,
      });
    };

    const subscription = getEntitiesNotificationStream(projectId, [entityId])
      .pipe(
        auditTime(1200),
        // we need to delay by a few seconds because of possible race condition between asking elastic search and reindexing
        delay(2000),
        applyPauseWhenNotUnderEdit(
          underEdit$,
          (value): value is MetadataChangedNotification => value?.messageType === 'MetadataChanged',
          syntheticNotification,
        ),
        map(updateEntityFromNotification),
      )
      .subscribe(dispatch);

    return () => subscription.unsubscribe();
  }, [callback, dispatch, entityId, entityType, projectId, underEdit$]);
}
