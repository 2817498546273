import { CustomizableConfig } from '@neptune/config-core-domain';
import { ImageSeriesEntry } from '@neptune/shared/image-series-domain';

import {
  loadState as loadLocalStorageState,
  LocalStorageState,
  persistState as persistLocalStorageState,
} from 'common/localStorage';

import { FeatureFlag, FeatureFlagConfig } from './featureFlag-types';
import { defineStorage } from './storage-base';
import { loadSessionStorageState, persistSessionStorageState } from './storage-session';

type SessionStorageState = {
  structureAsideCollapsed?: boolean;
  projectToolbarCollapsed?: boolean;
  forceDesktop?: boolean;
  compareChartsAveragingEnabledProjectNames?: string[];
  mutedTrialNotifications?: string[];
  mutedCardNotifications?: string[];
  mutedPaymentNotifications?: string[];
  mutedMonitoringNotifications?: string[];
  mutedStorageNotifications?: string[];
  pinnedThumbnails?: Record<string, Record<string, ImageSeriesEntry | undefined> | undefined>;
  customConfig: CustomizableConfig;
  chartTooltipConfig: Record<string, { isExpanded: boolean }>;
} & Record<FeatureFlag, boolean | undefined> &
  Record<FeatureFlagConfig, any>;

const sessionStorage = defineStorage<SessionStorageState>(
  loadSessionStorageState,
  persistSessionStorageState,
);

const localStorage = defineStorage<LocalStorageState>(
  loadLocalStorageState,
  persistLocalStorageState,
);

export const storage = {
  session: sessionStorage,
  local: localStorage,
};
