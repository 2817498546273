import React from 'react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';

import { ACTIONS_MENU_MAX_HEIGHT } from '@neptune/shared/venus-domain';

import { bemBlock } from '../../modules/bem';
import { getZIndex } from '../../modules/z-index';
import { ScrollArea } from '../scroll-area/ScrollArea';

import { ActionsMenuContentContext } from './actions-menu-content-provider';
import { ActionsMenuSeparator as Separator } from './ActionsMenuSeparator';

import './ActionsMenuContent.less';

const block = bemBlock('n-actions-menu-content');

type ContentOwnProps = {
  header?: React.ReactNode;
  footer?: React.ReactNode;
  triggerRef?: React.RefObject<HTMLElement>;
};

type ContentProps = React.ComponentPropsWithoutRef<typeof DropdownMenu.Content> & ContentOwnProps;

export const ActionsMenuContent = React.forwardRef<
  React.ElementRef<typeof DropdownMenu.Content>,
  ContentProps
>(({ className, children, header, footer, style, triggerRef, ...props }, ref) => {
  const contentContext = React.useMemo(() => {
    return {
      zIndex: (triggerRef?.current && getZIndex(triggerRef.current)) ?? undefined,
    };
  }, [triggerRef]);

  return (
    <ActionsMenuContentContext.Provider value={contentContext}>
      <DropdownMenu.Content
        ref={ref}
        className={block({
          extra: className,
        })}
        style={{
          ...style,
          zIndex: style?.zIndex ?? contentContext.zIndex,
        }}
        {...props}
      >
        {header && (
          <>
            <div className={block('header')}>{header}</div>
            <Separator />
          </>
        )}
        <ScrollArea>
          <ScrollArea.Viewport style={{ maxHeight: ACTIONS_MENU_MAX_HEIGHT }}>
            {children}
          </ScrollArea.Viewport>
          <ScrollArea.Scrollbar orientation="vertical" />
          <ScrollArea.Scrollbar orientation="horizontal" />
          <ScrollArea.Corner />
        </ScrollArea>
        {footer && (
          <>
            <Separator />
            <div className={block('footer')}>{footer}</div>
          </>
        )}
      </DropdownMenu.Content>
    </ActionsMenuContentContext.Provider>
  );
});
