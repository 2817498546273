import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Params } from 'router5/types/types/base';

import { CompareTab, RunDetailsTab } from '@neptune/project-runs-domain';
import { getCurrentRouteParams, navigateTo } from '@neptune/shared/routing-business-logic';

type DashboardMode = 'single' | 'compare';

export const useTabNavigation = () => {
  const dispatch = useDispatch();
  const params = useSelector(getCurrentRouteParams);

  const navigateToRunDetails = React.useCallback(
    (runDetailsTab: RunDetailsTab) => {
      dispatch(
        navigateTo('project.runs-tab', {
          ...params,
          tab: 'details',
          dashboardId: undefined,
          dash: undefined,
          ...runDetailsTab.routeParams,
          // Clear AttributeViewer params that collide between tabs (NPT-12651).
          path: undefined,
          attribute: undefined,
          file: undefined,
          filePath: undefined,
        }),
      );
    },
    [dispatch, params],
  );

  const navigateToCompare = React.useCallback(
    (compareTab: CompareTab) => {
      dispatch(
        navigateTo('project.runs-tab', {
          ...params,
          tab: 'compare',
          dashboardId: undefined,
          runIdentificationKey: undefined,
          ...compareTab.routeParams,
        }),
      );
    },
    [dispatch, params],
  );

  return { navigateToRunDetails, navigateToCompare };
};

export const pickTabName = (params: Params, dashboardMode: DashboardMode) =>
  params[dashboardMode === 'single' ? 'detailsTab' : 'dash'] || 'table';
export const shortcuts = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'];

export const determineTab = <T extends { dashboardId: string }>(
  list: T[],
  input: string | string[],
) => {
  if (typeof input === 'string') {
    return list.find((item) => item.dashboardId === input);
  }

  return list[shortcuts.findIndex((shortcut) => shortcut === input[0])];
};
