import React from 'react';

import { bemBlock } from '@neptune/shared/venus-ui';

import { MarkdownRenderer } from '../markdown-renderer';

import './EmptyViewMarkdownRenderer.less';

const block = bemBlock('empty-view-markdown-renderer');

export const EmptyViewMarkdownRenderer = ({
  content,
  className,
}: React.ComponentProps<typeof MarkdownRenderer>) => {
  return <MarkdownRenderer className={block({ extra: className })} content={content} />;
};
