import { SearchQueryTerm, SearchQueryTermType } from './types';

export function separateTermsByOrOperator(terms: SearchQueryTerm[]): SearchQueryTerm[][] {
  return terms.reduce<SearchQueryTerm[][]>(
    (groups, term) => {
      const isOrOperator = term.type === SearchQueryTermType.OPERATOR && term.value === 'OR';
      const currentGroup = groups[groups.length - 1];

      if (isOrOperator && currentGroup.length > 0) {
        groups.push([]);
        return groups;
      }

      if (!isOrOperator) {
        currentGroup.push(term);
      }

      return groups;
    },
    [[]],
  );
}
