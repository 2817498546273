import React from 'react';
import { useDispatch } from 'react-redux';
import { navigateTo } from 'router/actions';

import { useLocalModal } from '@neptune/shared/common-util';
import { makeProjectIdentifier } from '@neptune/shared/core-project-util';
import { Entity, trashEntities } from '@neptune/shared/entity-domain';

type UseTrashEntityParams = {
  organizationName: string;
  projectName: string;
  entity: Entity;
};

type UseTrashEntityValue = {
  isOpen: boolean;
  requestTrash: () => void;
  executeTrash: () => Promise<void>;
  cancelTrash: () => void;
};

export const useTrashEntity = ({
  organizationName,
  projectName,
  entity,
}: UseTrashEntityParams): UseTrashEntityValue => {
  const { close, isOpen, open } = useLocalModal();

  const dispatch = useDispatch();
  const projectIdentifier = makeProjectIdentifier(organizationName, projectName);
  const entityId = entity.id;

  const executeTrash = React.useCallback(async () => {
    await trashEntities({
      entityIds: [entityId],
      projectIdentifier,
    });

    close();

    dispatch(
      navigateTo('project.runs-tab', {
        organizationName,
        projectName,
        tab: 'table',
      }),
    );
  }, [close, dispatch, entityId, organizationName, projectName, projectIdentifier]);

  return {
    cancelTrash: close,
    executeTrash,
    isOpen,
    requestTrash: open,
  };
};
