import { createSelector } from 'reselect';

import { makeTreeFromArray } from '@neptune/shared/common-util';
import { getEntityShortId } from '@neptune/shared/entity-domain';
import { makeEntityIdentifier } from '@neptune/shared/entity-util';
import { getCurrentRouteParams } from '@neptune/shared/routing-business-logic';

import {
  AttributeDefinition,
  getRunIdentificationKeyFromAttributes,
} from 'domain/experiment/attribute';

import { CurrentEntityState } from './reducer';

const getCurrentEntityState = (state: any): CurrentEntityState => state.currentEntity;

const getCurrentBasicEntityInternal = (state: any) =>
  getCurrentEntityState(state).basicEntity.entity;
/** @deprecated todo: move out of redux */
const getCurrentEntityAttributeDefinitionsData = (state: any) =>
  getCurrentEntityState(state).attributeDefinitions;

export const getCurrentBasicEntityFetchStatus = createSelector(
  getCurrentEntityState,
  (state: CurrentEntityState) => state.basicEntity.fetchStatus,
);

export const getCurrentBasicEntityFetchError = createSelector(
  getCurrentEntityState,
  (state: CurrentEntityState) => state.basicEntity.error,
);

export const getCurrentBasicEntity = createSelector(
  [getCurrentBasicEntityInternal, getCurrentRouteParams],
  (entity, params) => {
    if (
      !entity ||
      !params.organizationName ||
      !params.projectName ||
      !params.runIdentificationKey
    ) {
      return undefined;
    }

    let runIdentificationKey = getEntityShortId(entity);

    if (params.type === 'experiment') {
      runIdentificationKey = getRunIdentificationKeyFromAttributes(entity.attributes) ?? '';
    }

    const experimentIdentifier = makeEntityIdentifier(
      entity.organizationName,
      entity.projectName,
      runIdentificationKey ?? '',
    );

    const expectedEntityId = makeEntityIdentifier(
      params.organizationName,
      params.projectName,
      params.runIdentificationKey,
    );

    if (experimentIdentifier !== expectedEntityId) {
      return undefined;
    }

    return entity;
  },
);

export const getCurrentEntityShortId = createSelector(getCurrentBasicEntity, (entity) => {
  if (!entity) {
    return undefined;
  }

  return getEntityShortId(entity);
});

export const getCurrentRunIdentificationKey = createSelector(getCurrentBasicEntity, (entity) => {
  if (!entity) {
    return undefined;
  }

  return getRunIdentificationKeyFromAttributes(entity.attributes);
});

export const getCurrentEntityId = createSelector(getCurrentBasicEntity, (entity) => {
  return entity?.id;
});

/** @deprecated todo: move out of redux */
export const getCurrentEntityAttributeDefinitionsStatus = createSelector(
  getCurrentEntityState,
  (state: CurrentEntityState) => state.attributeDefinitions.fetchStatus,
);

/** @deprecated todo: move out of redux */
export const getCurrentEntityAttributeDefinitionsError = createSelector(
  getCurrentEntityState,
  (state: CurrentEntityState) => state.attributeDefinitions.error,
);

/** @deprecated todo: move out of redux */
export const getCurrentEntityAttributeDefinitions = createSelector(
  [getCurrentEntityAttributeDefinitionsData, getCurrentBasicEntity, getCurrentRouteParams],
  (data, entity, params) => {
    if (!data || !entity || !params.organizationName || !params.projectName) {
      return undefined;
    }

    const expectedEntityId = makeEntityIdentifier(
      params.organizationName,
      params.projectName,
      getEntityShortId(entity),
    );

    if (data.forId !== expectedEntityId) {
      return undefined;
    }

    return data.attributeDefinitions;
  },
);

const getName = (entry: AttributeDefinition) => entry.name;

/** @deprecated todo: move out of redux */
export const getEntityAttributeTree = createSelector(
  [getCurrentEntityAttributeDefinitions],
  (attributes) => (attributes ? makeTreeFromArray(attributes, getName) : undefined),
);
