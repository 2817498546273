import { escapeRegExp, isEmpty } from 'lodash';

import { isValidRegExp } from '@neptune/shared/common-util';
import { AttributeNameFilter } from '@neptune/shared/search-domain';

import { separateTermsByOrOperator } from './separateTermsByOrOperator';
import { SearchQueryTerm, SearchQueryTermType } from './types';

export const createSearchModelConverter = () => {
  function fromSearchQueryToAttributeNameFilter(
    searchQuery: SearchQueryTerm[],
  ): Pick<AttributeNameFilter, 'mustMatchAny'> {
    if (isEmpty(searchQuery)) {
      return {
        mustMatchAny: [
          {
            mustMatchRegexes: [],
            mustNotMatchRegexes: [],
          },
        ],
      };
    }

    const groupedTerms = separateTermsByOrOperator(searchQuery);

    const mustMatchAny = groupedTerms.reduce<
      Pick<AttributeNameFilter, 'mustMatchRegexes' | 'mustNotMatchRegexes'>[]
    >((acc, terms) => {
      if (isEmpty(terms)) {
        return acc;
      }

      return [...acc, convertGroupToApiNameFilter(terms)];
    }, []);

    return {
      mustMatchAny,
    };
  }

  function fromSearchQueryToSingleAttributeNameFilter(searchQuery: SearchQueryTerm[]) {
    if (isEmpty(searchQuery)) {
      return {
        mustMatchAny: [
          {
            mustMatchRegexes: [],
            mustNotMatchRegexes: [],
          },
        ],
      };
    }

    return {
      mustMatchAny: [
        {
          mustMatchRegexes: [escapeRegExp(searchQuery[0].value)],
          mustNotMatchRegexes: [],
        },
      ],
    };
  }

  function fromDomainToTitle(terms: SearchQueryTerm[]): string {
    return terms.map((criterion) => criterion.value).join(' ');
  }

  function convertGroupToApiNameFilter([firstTerm, ...rest]: SearchQueryTerm[]) {
    return rest.reduce<Pick<AttributeNameFilter, 'mustMatchRegexes' | 'mustNotMatchRegexes'>>(
      (groups, term, index, allTerms) => {
        if (term.type === SearchQueryTermType.OPERATOR) {
          return groups;
        }

        if (!isValidValue(term.value)) {
          return groups;
        }

        const negate = index > 0 && isAndNotOperator(allTerms[index - 1]);

        if (negate) {
          return {
            ...groups,
            mustNotMatchRegexes: [...(groups.mustNotMatchRegexes ?? []), term.value],
          };
        }

        return {
          ...groups,
          mustMatchRegexes: [...(groups.mustMatchRegexes ?? []), term.value],
        };
      },
      {
        mustMatchRegexes: [firstTerm.value],
        mustNotMatchRegexes: [],
      },
    );
  }

  return {
    fromSearchQueryToAttributeNameFilter,
    fromDomainToTitle,
    fromSearchQueryToSingleAttributeNameFilter,
  };
};

function isAndNotOperator(term: SearchQueryTerm): boolean {
  return term.type === SearchQueryTermType.OPERATOR && term.value === 'AND NOT';
}

function isValidValue(value?: string) {
  return value && isValidRegExp(value);
}

export const searchModelConverter = createSearchModelConverter();
