/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AttributeNameFilterDTO
 */
export interface AttributeNameFilterDTO {
    /**
     * A list of filters representing disjunctions of conjunctive simple filters. The implementation is currently limited to 1 level of nesting. E.g. `(a AND b AND NOT c) OR (d AND e) OR (f AND g)` is supported, but `(a OR (b OR c))` is not supported. The latter should be expressed as `(a OR b OR c)`. `(a AND (b OR c))` is also not supported. Mutually exclusive with `mustMatchRegexes` and `mustNotMatchRegexes`.
     * @type {Array<AttributeNameFilterDTO>}
     * @memberof AttributeNameFilterDTO
     */
    mustMatchAny?: Array<AttributeNameFilterDTO>;
    /**
     * An attribute must match all of the regexes from the list to be returned. Mutually exclusive with `mustMatchAny`
     * @type {Array<string>}
     * @memberof AttributeNameFilterDTO
     */
    mustMatchRegexes?: Array<string>;
    /**
     * An attribute must match none of the regexes from the list to be returned. Mutually exclusive with `mustMatchAny`
     * @type {Array<string>}
     * @memberof AttributeNameFilterDTO
     */
    mustNotMatchRegexes?: Array<string>;
}

/**
 * Check if a given object implements the AttributeNameFilterDTO interface.
 */
export function instanceOfAttributeNameFilterDTO(value: object): value is AttributeNameFilterDTO {
    return true;
}

export function AttributeNameFilterDTOFromJSON(json: any): AttributeNameFilterDTO {
    return AttributeNameFilterDTOFromJSONTyped(json, false);
}

export function AttributeNameFilterDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): AttributeNameFilterDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'mustMatchAny': json['mustMatchAny'] == null ? undefined : ((json['mustMatchAny'] as Array<any>).map(AttributeNameFilterDTOFromJSON)),
        'mustMatchRegexes': json['mustMatchRegexes'] == null ? undefined : json['mustMatchRegexes'],
        'mustNotMatchRegexes': json['mustNotMatchRegexes'] == null ? undefined : json['mustNotMatchRegexes'],
    };
}

export function AttributeNameFilterDTOToJSON(value?: AttributeNameFilterDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'mustMatchAny': value['mustMatchAny'] == null ? undefined : ((value['mustMatchAny'] as Array<any>).map(AttributeNameFilterDTOToJSON)),
        'mustMatchRegexes': value['mustMatchRegexes'],
        'mustNotMatchRegexes': value['mustNotMatchRegexes'],
    };
}

