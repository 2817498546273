import React, { useCallback } from 'react';
import { ColorResult } from 'react-color';

import { useOnKeyDown } from 'common/hooks/useOnKeyDown';

import { SketchWithEyeDropper } from './SketchWithEyeDropper';

// Needs to listen to event on capturing phase, so we can revert the color on Escape before the component is unmounted
const ON_KEY_DOWN_OPTIONS: EventListenerOptions = { capture: true };
const EMPTY_PRESET_COLORS: string[] = [];

export const HiddenColorInput: React.FC<{
  className?: string;
  defaultValue?: string;
  presetColors?: string[];
  onChange?: (color: string) => void;
}> = ({ className, defaultValue, presetColors, onChange }) => {
  const [color, setColor] = React.useState(defaultValue);
  const originalColorRef = React.useRef(defaultValue);

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        if (!originalColorRef.current) {
          return;
        }

        onChange?.(originalColorRef.current);
      }
    },
    [onChange],
  );

  useOnKeyDown(handleKeyDown, ON_KEY_DOWN_OPTIONS);

  const handleColorChange = React.useCallback((color: ColorResult) => {
    setColor(color.hex);
  }, []);

  const handleColorChangeComplete = React.useCallback(
    (color: ColorResult) => {
      onChange?.(color.hex);
    },
    [onChange],
  );

  return (
    <div className={className} data-role={'hidden-color-input'}>
      <SketchWithEyeDropper
        color={color}
        onChange={handleColorChange}
        onChangeComplete={handleColorChangeComplete}
        presetColors={presetColors ?? EMPTY_PRESET_COLORS}
      />
    </div>
  );
};
