import { DefinedUseQueryResult, useQuery } from '@tanstack/react-query';

import { getProjectsDetails } from '@neptune/shared/project-domain';

const INITIAL_DATA: never[] = [];

export function useProjectsDetails<
  T extends (...args: unknown[]) => unknown,
  R = T extends (...args: unknown[]) => infer C ? C : never,
>(
  ids: string[],
  selector?: (projects: Awaited<ReturnType<typeof getProjectsDetails>>) => R,
): DefinedUseQueryResult<R> {
  return useQuery({
    retry: false,
    select: selector,
    suspense: true,
    initialData: INITIAL_DATA,
    queryKey: ['projects-details', [...ids].sort()],
    queryFn: async () => getProjectsDetails(ids),
  });
}
