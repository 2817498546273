import React from 'react';
import ReactDom from 'react-dom';
import { useSelector } from 'react-redux';

import { getCurrentProject } from '@neptune/current-project-business-logic';
import { useCurrentRunIdentity } from '@neptune/project-business-logic';
import {
  determineTab,
  pickTabName,
  useRunsViewMode,
  useTabNavigation,
} from '@neptune/project-runs-business-logic';
import { compareDefaultTabs } from '@neptune/project-runs-domain';
import { ProjectHeader } from '@neptune/project-ui';
import { getCurrentRouteParams } from '@neptune/shared/routing-business-logic';
import { OverlayView } from '@neptune/shared/venus-ui';

import { ProjectDropdownMenuContainer } from './ProjectDropdownMenuContainer';
import { useOverlaySubviews } from './useOverlaySubviews';

type ProjectHeaderContentContainerProps = {
  target: HTMLElement | null;
  tabs: React.ReactNode;
};

export const ProjectHeaderContainer: React.FC<ProjectHeaderContentContainerProps> = ({
  target,
  tabs,
}) => {
  const project = useSelector(getCurrentProject);
  const params = useSelector(getCurrentRouteParams);

  const { closeCurrentSubview, currentSubviewComponent, openSubview, getSubviewEntries } =
    useOverlaySubviews();
  const { dashboardMode } = useRunsViewMode();
  const { navigateToCompare } = useTabNavigation();

  const tabName = pickTabName(params, dashboardMode);

  const { currentRunIdentificationKey, currentRunName } = useCurrentRunIdentity();

  const subviewEntries = React.useMemo(
    () => getSubviewEntries(project),
    [getSubviewEntries, project],
  );

  const handleBackToCompare = React.useCallback(() => {
    const compareModeFallbackTab = determineTab(compareDefaultTabs, 'table');
    const compareTab = determineTab(compareDefaultTabs, tabName) || compareModeFallbackTab;

    if (!compareTab) {
      return;
    }

    navigateToCompare(compareTab);
  }, [tabName, navigateToCompare]);

  if (!target || !project) {
    return null;
  }

  return ReactDom.createPortal(
    <>
      <ProjectHeader
        runIdentificationKey={currentRunIdentificationKey}
        menu={
          <ProjectDropdownMenuContainer
            openSubview={openSubview}
            subviewEntries={subviewEntries}
            project={project}
          />
        }
        tabs={tabs}
        onBackToCompare={handleBackToCompare}
        dashboardMode={dashboardMode}
        singleRunHeaderName={currentRunName}
      />
      <OverlayView
        children={currentSubviewComponent || null}
        onClose={closeCurrentSubview}
        isVisible={!!currentSubviewComponent}
      />
    </>,
    target,
  );
};
