import { pick } from 'lodash';
import { Params } from 'router5/types/types/base';

import { RouterSimpleState } from '@neptune/shared/routing-domain';

import { Dashboard } from 'domain/dashboard';

export type DashboardInfo = Pick<Dashboard, 'versionBranchId' | 'id'> &
  Partial<Pick<Dashboard, 'name'>>;

const DETAILS_DASHBOARD_PARAMS_WHITELIST = [
  'runIdentificationKey',
  'distraction-free',
  'organizationName',
  'projectName',
  'type',
  'tab',
] as const;

const COMPARE_DASHBOARD_PARAMS_WHITELIST = [
  'distraction-free',
  'organizationName',
  'lbViewUnpacked',
  'experimentsOnly',
  'runsLineage',
  'projectName',
  'compare',
  'viewId',
  'query',
  'tab',
] as const;

export function getCleanedDashboardParams(params: Params): Params {
  if (params.tab === 'details') {
    return pick(params, DETAILS_DASHBOARD_PARAMS_WHITELIST);
  }

  if (params.tab === 'compare') {
    return pick(params, COMPARE_DASHBOARD_PARAMS_WHITELIST);
  }

  return params;
}

export function makeDashboardRoute(
  params: Params,
  { versionBranchId, id }: DashboardInfo,
  versionView?: 'versionView',
): RouterSimpleState | undefined {
  if (params.tab === 'details') {
    return {
      name: 'project.runs-tab',
      params: {
        ...params,
        tab: 'details',
        detailsTab: 'dashboard',
        dashboardId: versionBranchId,
        dashVersionId: versionView && id,
      },
    };
  }

  if (params.tab === 'compare') {
    return {
      name: 'project.runs-tab',
      params: {
        ...params,
        tab: 'compare',
        dash: 'dashboard',
        dashboardId: versionBranchId,
        dashVersionId: versionView && id,
      },
    };
  }
}
