import { AttributeType } from 'domain/experiment/attribute';

export type Widget = {
  id: string;
  name?: string;
  type: WidgetType;
  createdAt?: number;
  options?: WidgetOptions;
  sources: WidgetSource[];
};

export enum WidgetSourceType {
  ATTRIBUTE = 'attribute',
  CUSTOM_Y_EXPRESSION = 'custom-y-expression',
  NAMESPACE = 'namespace',
  ATTRIBUTE_PATTERN = 'attribute-pattern',
  FREE_TEXT = 'free-text',
}

export type AttributePatternWidgetSource = {
  type: WidgetSourceType.ATTRIBUTE_PATTERN;
  value: string;
};

export type CustomYExpressionWidgetSource = {
  type: WidgetSourceType.CUSTOM_Y_EXPRESSION;
  value: string;
  metadata: {
    alias?: string;
  };
};

export type NamespaceWidgetSource = {
  type: WidgetSourceType.NAMESPACE;
  value: string;
};

export type TextWidgetSource = {
  type: WidgetSourceType.FREE_TEXT;
  value?: string;
};

export type AttributeWidgetSource = {
  type: WidgetSourceType.ATTRIBUTE;
  value: string;
  metadata: {
    attributeType: AttributeType;
    subproperty?: string;
  };
};

export type WidgetSource =
  | TextWidgetSource
  | AttributeWidgetSource
  | NamespaceWidgetSource
  | CustomYExpressionWidgetSource
  | AttributePatternWidgetSource;

export type WidgetType =
  | 'chart'
  | 'valueList'
  | 'file'
  | 'fileSet'
  | 'gallery'
  | 'image'
  | 'imageComparison'
  | 'interactiveTable'
  | 'scatterPlot'
  | 'singleValue'
  | 'textNode'
  | 'table'
  | 'section'
  | 'notSupported';

export type WidgetOptions = object | undefined;

export function isAttributeSource(source: WidgetSource): source is AttributeWidgetSource {
  return source.type === WidgetSourceType.ATTRIBUTE;
}

export function isAttributePatternSource(
  source: WidgetSource,
): source is AttributePatternWidgetSource {
  return source.type === WidgetSourceType.ATTRIBUTE_PATTERN;
}

export function isYCustomExpressionSource(
  source: WidgetSource,
): source is CustomYExpressionWidgetSource {
  return source.type === WidgetSourceType.CUSTOM_Y_EXPRESSION;
}

export function isTextSource(source: WidgetSource): source is TextWidgetSource {
  return source.type === WidgetSourceType.FREE_TEXT;
}

export function isFloatSeriesSource(source: WidgetSource): source is AttributeWidgetSource {
  return isAttributeSource(source) && source.metadata.attributeType === 'floatSeries';
}
