import { AppConfig } from '@neptune/config-core-domain';

import config from 'config';

class NeptuneUrls {
  constructor(private config: AppConfig) {}

  get alternative(): string | undefined {
    return this.config.alternativeURL;
  }

  get apiModelDoc(): string {
    return `${this.config.documentationURL}/api/model`;
  }

  get apiSysFailedDoc(): string {
    return `${this.config.documentationURL}/api/sys#failed`;
  }

  get apiSysStateDoc(): string {
    return `${this.config.documentationURL}/api/sys#state`;
  }

  get archivingProjectsDoc(): string {
    return `${this.config.documentationURL}/management/archiving_project/`;
  }

  get blog(): string {
    return this.config.blogURL;
  }

  get contactEmail(): string {
    return this.config.contactEmail;
  }

  get customDashboardDoc(): string {
    return `${this.config.documentationURL}/custom_dashboard/`;
  }

  get goToOnboarding(): string {
    return `${this.config.onboardingLandingPageURL}`;
  }

  get gettingHelpDoc(): string {
    return `${this.config.documentationURL}/getting_help`;
  }

  get groupByDoc(): string {
    return `${this.config.documentationURL}/groupby`;
  }

  get helloWorldCollab(): string {
    return this.config.helloWorldCollabUrl;
  }

  get integrationCatalystDoc(): string {
    return `${this.config.documentationURL}/integrations/catalyst`;
  }

  get integrationDalexDoc(): string {
    return `${this.config.documentationURL}/integrations/dalex`;
  }

  get integrationFastaiDoc(): string {
    return `${this.config.documentationURL}/integrations/fastai`;
  }

  get integrationKerasTunerDoc(): string {
    return `${this.config.documentationURL}/integrations/keras-tuner`;
  }

  get integrationLightgbmDoc(): string {
    return `${this.config.documentationURL}/integrations/lightgbm`;
  }

  get integrationMlflowDoc(): string {
    return `${this.config.documentationURL}/integrations/mlflow`;
  }

  get integrationOptunaDoc(): string {
    return `${this.config.documentationURL}/integrations/optuna`;
  }

  get integrationPythonDoc(): string {
    return `${this.config.documentationURL}/usage/adding_neptune_to_your_code`;
  }

  get integrationPyTorchDoc(): string {
    return `${this.config.documentationURL}/integrations/pytorch`;
  }

  get integrationPyTorchIgniteDoc(): string {
    return `${this.config.documentationURL}/integrations/pytorch-ignite`;
  }

  get integrationPyTorchLightningDoc(): string {
    return `${this.config.documentationURL}/integrations/lightning`;
  }

  get integrationRDoc(): string {
    return `${this.config.documentationURL}/integrations/r`;
  }

  get integrationSacredDoc(): string {
    return `${this.config.documentationURL}/integrations/sacred`;
  }

  get integrationScikitLearnDoc(): string {
    return `${this.config.documentationURL}/integrations/sklearn`;
  }

  get integrationScikitOptimizeDoc(): string {
    return `${this.config.documentationURL}/integrations/scikit-optimize`;
  }

  get integrationsDoc(): string {
    return `${this.config.documentationURL}/integrations`;
  }

  get integrationSkorchDoc(): string {
    return `${this.config.documentationURL}/integrations/skorch`;
  }

  get integrationTensorboardDoc(): string {
    return `${this.config.documentationURL}/integrations/tensorboard`;
  }

  get integrationTensorflowKerasDoc(): string {
    return `${this.config.documentationURL}/integrations/keras`;
  }

  get integrationXgboostDoc(): string {
    return `${this.config.documentationURL}/integrations/xgboost`;
  }

  get loggingDependenciesDoc(): string {
    return `${this.config.documentationURL}/logging/dependencies/`;
  }

  get loggingGitInformationDoc(): string {
    return `${this.config.documentationURL}/logging/automatic#git-information`;
  }

  get loggingHtmlDoc(): string {
    return `${this.config.documentationURL}/logging/html`;
  }

  get loggingImagesDoc(): string {
    return `${this.config.documentationURL}/logging/images`;
  }

  get loggingMetadataDoc(): string {
    return `${this.config.documentationURL}/model_registry/registering_model/#logging-metadata-to-the-model`;
  }

  get loggingMetricsDoc(): string {
    return `${this.config.documentationURL}/log_metrics`;
  }

  get loggingProjectMetadataDoc(): string {
    return `${this.config.documentationURL}/logging/project_metadata`;
  }

  get loggingSourceCodeDoc(): string {
    return `${this.config.documentationURL}/logging/source_code`;
  }

  get loggingSystemMetricsDoc(): string {
    return `${this.config.documentationURL}/logging/system_metrics`;
  }

  get modelRegistryOverviewDoc(): string {
    return `${this.config.documentationURL}/model_registry/overview`;
  }

  get onPremisesIntroDoc(): string {
    return `${this.config.documentationURL}/about/on-prem_intro`;
  }

  get onPremReleaseNotes(): string {
    return this.config.onPremReleaseNotesUrl;
  }

  get pricing(): string {
    return this.config.pricingURL;
  }

  get pricingFaq(): string {
    return this.config.pricingFaqURL;
  }

  get privacyPolicy(): string | undefined {
    return this.config.privacyPolicyUrl;
  }

  get queryingMetadataDoc(): string {
    return `${this.config.documentationURL}/query_metadata`;
  }

  get quickStartDoc(): string {
    return `${this.config.documentationURL}/quickstart`;
  }

  get integrationQuickStartDocs(): string {
    return `${this.config.integrationQuickStartURL}`;
  }

  get reportsDoc(): string {
    return `${this.config.documentationURL}/reports/`;
  }

  get remoteStopAndAbortDoc(): string {
    return `${this.config.documentationURL}/app/remote_stop_and_abort`;
  }

  get roadmap(): string {
    return this.config.roadmapUrl;
  }

  get selectingFieldsForLegendDoc(): string {
    return `${this.config.documentationURL}/app/selecting_fields_for_legend/`;
  }

  get settingProjectNameDoc(): string {
    return `${this.config.documentationURL}/projects`;
  }

  get setupApiTokenDoc(): string {
    return `${this.config.documentationURL}/api_token`;
  }

  get smoothingDoc(): string {
    return `${this.config.documentationURL}/smoothing`;
  }

  get statusPage(): string | undefined {
    return this.config.statusPageUrl;
  }

  get storageTipsDoc(): string {
    return `${this.config.documentationURL}/help/storage_tips/`;
  }

  get termsOfService(): string | undefined {
    return this.config.termsOfServiceUrl;
  }

  get toolAltairDoc(): string {
    return `${this.config.documentationURL}/tools/altair`;
  }

  get toolBokehDoc(): string {
    return `${this.config.documentationURL}/tools/bokeh`;
  }

  get toolJupyterOverviewDoc(): string {
    return `${this.config.documentationURL}/tools/jupyter/overview`;
  }

  get toolMatplotlibDoc(): string {
    return `${this.config.documentationURL}/tools/matplotlib`;
  }

  get toolPandasDoc(): string {
    return `${this.config.documentationURL}/tools/pandas`;
  }

  get toolPlotlyDoc(): string {
    return `${this.config.documentationURL}/tools/plotly`;
  }

  get tutorialsDoc(): string {
    return `${this.config.documentationURL}/tutorials`;
  }

  get workspaceOrProjectReadOnlyDoc(): string {
    return `${this.config.documentationURL}/help/workspace_or_project_read_only/`;
  }

  get workspaceReadOnlyDoc(): string {
    return `${this.config.documentationURL}/help/workspace_read_only`;
  }

  get workspacesAndProjectsDoc(): string {
    return `${this.config.documentationURL}/about/workspaces_and_projects`;
  }

  get www(): string {
    return this.config.wwwUrl;
  }

  get onpremInternalDoc(): string {
    return `${this.config.onpremInternalDocsURL}`;
  }

  get dynamicSelection(): string {
    return `${this.config.documentationURL}/chart_widget#dynamic-metric-selection`;
  }

  get getCustomExpressions(): string {
    return `${this.config.documentationURL}/chart_widget#custom-expressions`;
  }
}

export const neptuneUrls = new NeptuneUrls(config);
