import React from 'react';

import { bemBlock, Button, Icon, SimpleTooltip } from '@neptune/shared/venus-ui';

import './EyeDropper.less';

const block = bemBlock('eye-dropper');

interface EyeDropperProps {
  onChange: (color: string) => void;
}

export function EyeDropper({ onChange }: EyeDropperProps) {
  const isEyeDropperSupported = 'EyeDropper' in window;

  const handleOpenDropper = async () => {
    if (!isEyeDropperSupported) {
      return;
    }

    try {
      // @ts-expect-error EyeDropper is experimental and does not have types
      // eslint-disable-next-line no-undef
      const eyeDropper = new window.EyeDropper();

      const result = await eyeDropper.open();
      onChange(result.sRGBHex);
    } catch {}
  };

  return (
    <SimpleTooltip
      content={
        isEyeDropperSupported ? undefined : 'The eye dropper tool requires Chrome or Edge browser.'
      }
    >
      <Button
        size="sm"
        className={block()}
        variant="secondary-text"
        onClick={handleOpenDropper}
        disabled={!isEyeDropperSupported}
      >
        <Icon glyph="eye-dropper" fillRule="evenodd" />
      </Button>
    </SimpleTooltip>
  );
}
