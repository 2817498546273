import React from 'react';
import * as SliderPrimitive from '@radix-ui/react-slider';

import { bemBlock } from '../../modules/bem';

import './SliderRoot.less';

const block = bemBlock('n-slider');

export const Root = React.forwardRef<
  React.ElementRef<typeof SliderPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof SliderPrimitive.Root>
>(({ className, onValueCommit, ...rest }, ref) => {
  // Track if we're currently sliding
  const isSliding = React.useRef(false);

  return (
    <SliderPrimitive.Root
      ref={ref}
      className={block({ extra: className })}
      onPointerDown={(e) => {
        isSliding.current = true;
        rest.onPointerDown?.(e);
      }}
      onLostPointerCapture={(e) => {
        // https://linear.app/neptuneai/issue/FLEX-563/investigate-important-bug-for-the-release
        // onLostPointerCapture is not implemented in Radix UI.
        if (isSliding.current) {
          isSliding.current = false;
          // Call onValueCommit with current values when pointer capture is lost
          onValueCommit?.(rest.value ?? rest.defaultValue ?? []);
        }

        rest.onLostPointerCapture?.(e);
      }}
      {...rest}
    />
  );
});

Root.displayName = 'Slider.Root';
