import React from 'react';

import { Entity } from '@neptune/shared/entity-domain';

import {
  useEntitiesWithAttributesFetching,
  UseEntitiesWithAttributesFetchingParams,
} from './use-entities-with-attributes-fetching';

export function useEntityWithAttributesFetching<E = unknown>({
  entity,
  requestedAttributes,
  allowRequestClustering,
  skipFetch,
  errorFormatter,
}: Omit<UseEntitiesWithAttributesFetchingParams<E>, 'entities'> & { entity: Entity | undefined }) {
  const entities: Entity[] | undefined = React.useMemo(() => entity && [entity], [entity]);

  const {
    entities: fetchedEntities,
    error,
    loading,
    refresh,
  } = useEntitiesWithAttributesFetching({
    entities,
    requestedAttributes,
    allowRequestClustering,
    skipFetch,
    errorFormatter,
  });

  return {
    entity: fetchedEntities?.[0],
    error,
    loading,
    refresh,
  };
}
