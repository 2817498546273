import React from 'react';

import { GlyphName } from '@neptune/shared/core-glyphs-domain';
import { DropdownToggleInterface } from '@neptune/shared/venus-domain';

import { DropdownItem, DropdownItemProps } from '../dropdown-item/DropdownItem';

export const DropdownSubmenuToggle = ({
  onToggle,
  label,
  icon,
  'data-role': dataRole,
  elementRef,
  component,
  disableChevron,
}: DropdownToggleInterface & {
  label: string;
  icon?: GlyphName;
  'data-role'?: string;
  elementRef?: any;
  component?: DropdownItemProps<{}>['component'];
}) => (
  <DropdownItem
    submenu={!disableChevron}
    icon={icon}
    label={label}
    onClick={onToggle}
    width="100%"
    data-role={dataRole}
    elementRef={elementRef}
    component={component}
  />
);
