import { WidgetDTOTypeEnum } from '@neptune/shared/core-apis-leaderboard-domain';
import type { WidgetType } from '@neptune/shared/widgets-domain';

import { invertMapTyped } from 'common/tsHelpers';

const widgetTypeMapping: Record<Exclude<WidgetType, 'notSupported'>, WidgetDTOTypeEnum> = {
  chart: WidgetDTOTypeEnum.Chart,
  file: WidgetDTOTypeEnum.File,
  fileSet: WidgetDTOTypeEnum.FileSet,
  gallery: WidgetDTOTypeEnum.Gallery,
  image: WidgetDTOTypeEnum.Image,
  imageComparison: WidgetDTOTypeEnum.ImageComparison,
  interactiveTable: WidgetDTOTypeEnum.InteractiveTable,
  valueList: WidgetDTOTypeEnum.ValueList,
  scatterPlot: WidgetDTOTypeEnum.ScatterPlot,
  singleValue: WidgetDTOTypeEnum.SingleValue,
  table: WidgetDTOTypeEnum.Table,
  section: WidgetDTOTypeEnum.Section,
  textNode: WidgetDTOTypeEnum.TextNode,
} as const;

const invertedWidgetTypeMapping = invertMapTyped(widgetTypeMapping);

export function widgetTypeFromApiToDomain(widgetType: WidgetDTOTypeEnum): WidgetType {
  return invertedWidgetTypeMapping[widgetType] || 'notSupported';
}

export function widgetTypeFromDomainToApi(widgetType: WidgetType): WidgetDTOTypeEnum | undefined {
  if (widgetType === 'notSupported') {
    return undefined;
  }

  return widgetTypeMapping[widgetType];
}
