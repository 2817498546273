/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SingleTimeSeriesViewBucket
 */
export interface SingleTimeSeriesViewBucket {
    /**
     * 
     * @type {number}
     * @memberof SingleTimeSeriesViewBucket
     */
    bucketNo: number;
    /**
     * 
     * @type {number}
     * @memberof SingleTimeSeriesViewBucket
     */
    count: number;
    /**
     * 
     * @type {number}
     * @memberof SingleTimeSeriesViewBucket
     */
    maxY: number;
    /**
     * 
     * @type {number}
     * @memberof SingleTimeSeriesViewBucket
     */
    minY: number;
    /**
     * 
     * @type {number}
     * @memberof SingleTimeSeriesViewBucket
     */
    sum: number;
}

/**
 * Check if a given object implements the SingleTimeSeriesViewBucket interface.
 */
export function instanceOfSingleTimeSeriesViewBucket(value: object): value is SingleTimeSeriesViewBucket {
    if (!('bucketNo' in value) || value['bucketNo'] === undefined) return false;
    if (!('count' in value) || value['count'] === undefined) return false;
    if (!('maxY' in value) || value['maxY'] === undefined) return false;
    if (!('minY' in value) || value['minY'] === undefined) return false;
    if (!('sum' in value) || value['sum'] === undefined) return false;
    return true;
}

export function SingleTimeSeriesViewBucketFromJSON(json: any): SingleTimeSeriesViewBucket {
    return SingleTimeSeriesViewBucketFromJSONTyped(json, false);
}

export function SingleTimeSeriesViewBucketFromJSONTyped(json: any, ignoreDiscriminator: boolean): SingleTimeSeriesViewBucket {
    if (json == null) {
        return json;
    }
    return {
        
        'bucketNo': json['bucketNo'],
        'count': json['count'],
        'maxY': json['maxY'],
        'minY': json['minY'],
        'sum': json['sum'],
    };
}

export function SingleTimeSeriesViewBucketToJSON(value?: SingleTimeSeriesViewBucket | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'bucketNo': value['bucketNo'],
        'count': value['count'],
        'maxY': value['maxY'],
        'minY': value['minY'],
        'sum': value['sum'],
    };
}

