import React from 'react';
import { useSelector } from 'react-redux';

// todo this dependency should be changed in the future to depend on currentProject explicitly, not through redux (parents, context etc.)
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { getCurrentProject } from '@neptune/current-project-business-logic';
import {
  hasEntityAbortPermission,
  hasEntityDeletePermission,
  hasEntityModifyPermission,
} from '@neptune/shared/core-permissions-business-logic';
import { ProjectWithRole } from '@neptune/shared/core-project-domain';
import { WithPermissions } from '@neptune/shared/project-domain';

export function usePermissionEnhancedEntries<T extends { projectId: string }>(
  entries: T[],
): WithPermissions<T>[] {
  const currentProject = useSelector(getCurrentProject);
  return React.useMemo(
    () => entries.map((entry) => entryPermissionsEnhance(currentProject, entry)),
    [entries, currentProject],
  );
}

export function usePermissionEnhancedEntry<T extends { projectId: string }>(
  entry: T | undefined,
): WithPermissions<T> | undefined;
export function usePermissionEnhancedEntry<T extends { projectId: string }>(
  entry: T,
): WithPermissions<T>;
export function usePermissionEnhancedEntry(entry: undefined): undefined;

export function usePermissionEnhancedEntry<T extends { projectId: string }>(
  entry: T | undefined,
): WithPermissions<T> | undefined {
  const currentProject = useSelector(getCurrentProject);
  return React.useMemo(
    () => (entry ? entryPermissionsEnhance(currentProject, entry) : undefined),
    [currentProject, entry],
  );
}

export function entryPermissionsEnhance<T extends object>(
  project: ProjectWithRole | undefined,
  entry: T,
): WithPermissions<T>;
export function entryPermissionsEnhance(
  project: ProjectWithRole | undefined,
  entry: undefined,
): undefined;

export function entryPermissionsEnhance<T extends { projectId: string }>(
  project: ProjectWithRole | undefined,
  entry: T | undefined,
): WithPermissions<T> | undefined {
  if (!entry) {
    return undefined;
  }

  if (entry.projectId !== project?.id) {
    const canModify = hasEntityModifyPermission(undefined);
    const canDelete = hasEntityDeletePermission(undefined);
    const canAbort = hasEntityAbortPermission(undefined);

    return {
      ...entry,
      canModify,
      canDelete,
      canAbort,
    };
  }

  const canModify = hasEntityModifyPermission(project);
  const canDelete = hasEntityDeletePermission(project);
  const canAbort = hasEntityAbortPermission(project);

  return {
    ...entry,
    canModify,
    canDelete,
    canAbort,
  };
}
