import React from 'react';

import { bemBlock, Icon, IconStack } from '@neptune/shared/venus-ui';

import './IconWithIndicator.less';

const block = bemBlock('icon-with-indicator');

export function IconWithIndicator({
  glyph,
  showIndicator,
  size = 'lg',
  className,
  transform = { x: 0.4, y: -0.2 },
}: {
  glyph: React.ComponentProps<typeof Icon>['glyph'];
  showIndicator: boolean;
  size?: React.ComponentProps<typeof Icon>['size'];
  className?: string;
  transform?: React.ComponentProps<typeof Icon>['transform'];
}) {
  return (
    <IconStack className={block({ extra: className })}>
      <Icon color="text-alt" size={size} fixedWidth glyph={glyph} />
      {showIndicator && (
        <Icon className={block('dot-indicator')} size={size} transform={transform} glyph="dot" />
      )}
    </IconStack>
  );
}
