import React, { FC } from 'react';

import { bemBlock } from '../../modules/bem';
import { LayoutRow } from '../layout-row/LayoutRow';
import { Text } from '../text/Text';

import { useNavigationTabsContext } from './NavigationTabsContext';

import './NavigationTabsItem.less';

const block = bemBlock('navigation-tabs-item');

type NavigationTabsItemProps = {
  disabled?: boolean;
  tabId: string;
  'data-role'?: string;
};

type UseNavigationTabsItemUtilsProps = Pick<NavigationTabsItemProps, 'tabId'>;

const useNavigationTabsItemUtils = ({ tabId }: UseNavigationTabsItemUtilsProps) => {
  const { activeTabId, onChange } = useNavigationTabsContext();

  const active = activeTabId === tabId;

  const handleChange = React.useCallback(() => {
    if (active) {
      return;
    }

    onChange?.(tabId);
  }, [active, onChange, tabId]);

  return {
    active,
    handleChange,
  };
};

export const NavigationTabsItem: FC<NavigationTabsItemProps> = ({
  disabled,
  tabId,
  'data-role': dataRole,
  children,
}) => {
  const { active, handleChange } = useNavigationTabsItemUtils({
    tabId,
  });

  return (
    <button
      data-role={dataRole}
      disabled={disabled}
      className={block({
        modifiers: {
          active,
        },
      })}
      onClick={handleChange}
      data-tab-item={tabId}
      data-selected-tab={active}
    >
      <LayoutRow spacedChildren="sm" alignContent="center" alignItems="center" overflow="hidden">
        {children && (
          <Text size="sm" fontWeight="semibold" className={block('text')}>
            {children}
          </Text>
        )}
      </LayoutRow>
    </button>
  );
};
