import React from 'react';
import { useSelector } from 'react-redux';

import { getCurrentProject } from '@neptune/current-project-business-logic';
import { Layout } from '@neptune/shared/venus-ui';

import { ProjectStorageBannersContainer } from './ProjectStorageBannersContainer';

export const ProjectHeaderWrapperContainer = React.forwardRef<HTMLElement>((props, ref) => {
  const project = useSelector(getCurrentProject);

  if (!project) {
    return null;
  }

  return (
    <>
      <ProjectStorageBannersContainer project={project} />
      <Layout.Element elementRef={ref} id="project-header-slot--portal" />
    </>
  );
});
