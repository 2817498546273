export enum SearchQueryTermType {
  CRITERION = 'CRITERION',
  OPERATOR = 'OPERATOR',
}

export type SearchQueryTerm = {
  type: SearchQueryTermType;
  value: string;
};

export type RecentSearch = {
  id: string;
  terms: SearchQueryTerm[];
};

export type FieldResult = {
  id: string;
  value: string;
};
