import { Entity, isEntityEquals } from '@neptune/shared/entity-domain';
import { makeEntityIdentifier } from '@neptune/shared/entity-util';

import { getSingleRunAutoFollowEnabled } from 'common/featureFlag';
import {
  AttributeDefinition,
  getNameFromAttributes,
  knownAttributes,
} from 'domain/experiment/attribute';
import { fetchStatus as FetchStatus } from 'state/fetch-status';

import {
  CurrentBasicEntityActionTypes,
  CurrentEntityActions,
  CurrentEntityAttributeDefinitionsActionTypes,
  UpdateCurrentBasicEntityActionTypes,
  UpdateCurrentEntityAttributeDefinitionsActionTypes,
} from './actions';

type BasicEntityState = {
  fetchStatus: FetchStatus;
  entity?: Entity;
  error: unknown | null;
  requestedId: string;
  receivedId: string;
};
/** @deprecated todo: move out of redux */
type CurrentEntityAttributeDefinitionsState = {
  fetchStatus: FetchStatus;
  attributeDefinitions?: AttributeDefinition[];
  forId: string;
  error: unknown | null;
  lastRequestedId: string;
};
export type CurrentEntityState = {
  /** @deprecated todo: move out of redux */
  attributeDefinitions: CurrentEntityAttributeDefinitionsState;
  basicEntity: BasicEntityState;
};

const initialState: CurrentEntityState = {
  /** @deprecated todo: move out of redux */
  attributeDefinitions: {
    fetchStatus: FetchStatus.NONE,
    attributeDefinitions: undefined,
    error: null,
    forId: '',
    lastRequestedId: '',
  },
  basicEntity: {
    fetchStatus: FetchStatus.NONE,
    entity: undefined,
    error: null,
    requestedId: '',
    receivedId: '',
  },
};

export function currentEntityReducer(
  state: CurrentEntityState = initialState,
  action: CurrentEntityActions,
): CurrentEntityState {
  switch (action.type) {
    case CurrentBasicEntityActionTypes.request: {
      const requestedId = makeEntityIdentifier(
        action.payload.organizationName,
        action.payload.projectName,
        action.payload.runIdentificationKey,
      );
      return {
        ...state,
        basicEntity: {
          ...state.basicEntity,
          fetchStatus: FetchStatus.PENDING,
          requestedId,
          error: null,
        },
      };
    }

    case CurrentBasicEntityActionTypes.fail: {
      return {
        ...state,
        basicEntity: {
          ...state.basicEntity,
          fetchStatus: FetchStatus.FAILED,
          error: action.error,
          entity: undefined,
        },
      };
    }

    case CurrentBasicEntityActionTypes.success: {
      return {
        ...state,
        basicEntity: {
          ...state.basicEntity,
          fetchStatus: FetchStatus.SUCCESS,
          entity: action.payload,
          error: null,
        },
      };
    }

    case UpdateCurrentBasicEntityActionTypes.success: {
      if (!state.basicEntity.entity) {
        return state;
      }

      const prevExperimentName = getNameFromAttributes(state.basicEntity.entity.attributes);
      const experimentName = getNameFromAttributes(action.payload.attributes);

      const prevId = state.basicEntity.entity?.id;
      const id = action.payload.id;

      if (getSingleRunAutoFollowEnabled()) {
        if (
          (prevExperimentName !== '' && experimentName === '') ||
          (prevExperimentName && experimentName !== prevExperimentName) ||
          (!prevExperimentName && id !== prevId) ||
          (makeSureEntityIsSmallEnoughForDeepCompare(state.basicEntity.entity) &&
            isEntityEquals(action.payload, state.basicEntity.entity))
        ) {
          return state;
        }
      } else {
        if (
          id !== prevId ||
          (makeSureEntityIsSmallEnoughForDeepCompare(state.basicEntity.entity) &&
            isEntityEquals(action.payload, state.basicEntity.entity))
        ) {
          return state;
        }
      }

      return {
        ...state,
        basicEntity: {
          ...state.basicEntity,
          entity: action.payload,
          error: null,
        },
      };
    }

    case CurrentEntityAttributeDefinitionsActionTypes.request: {
      const requestedId = makeEntityIdentifier(
        action.payload.organizationName,
        action.payload.projectName,
        action.payload.runIdentificationKey,
      );
      return {
        ...state,
        attributeDefinitions: {
          ...state.attributeDefinitions,
          fetchStatus: FetchStatus.PENDING,
          lastRequestedId: requestedId,
          error: null,
        },
      };
    }

    case CurrentEntityAttributeDefinitionsActionTypes.fail: {
      const requestId = makeEntityIdentifier(
        action.payload.organizationName,
        action.payload.projectName,
        action.payload.runIdentificationKey,
      );

      if (state.attributeDefinitions.lastRequestedId !== requestId) {
        return state;
      }

      return {
        ...state,
        attributeDefinitions: {
          ...state.attributeDefinitions,
          fetchStatus: FetchStatus.FAILED,
          error: action.error,
          attributeDefinitions: undefined,
        },
      };
    }

    case CurrentEntityAttributeDefinitionsActionTypes.success: {
      if (state.attributeDefinitions.lastRequestedId !== action.payload.requestId) {
        return state;
      }

      return {
        ...state,
        attributeDefinitions: {
          ...state.attributeDefinitions,
          fetchStatus: FetchStatus.SUCCESS,
          attributeDefinitions: action.payload.result,
          forId: action.payload.requestId,
          error: null,
        },
      };
    }

    case UpdateCurrentEntityAttributeDefinitionsActionTypes.success: {
      if (
        // ensure that there is no regular fetch for attribute definitions
        state.attributeDefinitions.fetchStatus !== 'success' ||
        // ensure that the received attribute definitions match the current fetched basic entity
        state.basicEntity.fetchStatus !== 'success' ||
        state.basicEntity.entity?.id !== action.payload.identifier
      ) {
        return state;
      }

      return {
        ...state,
        attributeDefinitions: {
          ...state.attributeDefinitions,
          attributeDefinitions: action.payload.result,
          error: null,
        },
      };
    }

    default:
      return state;
  }
}

function makeSureEntityIsSmallEnoughForDeepCompare(entity: Entity) {
  return entity.attributes.length < 2 * knownAttributes.length;
}
