// Libs
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';


// App
import {
  getGenericModalState,
} from 'state/selectors';
import {
  SERVER_ERROR_MODAL_NAME,
  hideServerErrorModal,
} from 'state/ui/modals/server-error/actions';
import { ServerErrorModal } from './ServerErrorModal';


// Module
const propTypes = {
  closeModal: PropTypes.func,
  isOpen: PropTypes.bool,
};


const ServerErrorModalContainer = ({
  closeModal,
  isOpen,
}) => {
  return (
    <ServerErrorModal
      isOpen={isOpen}
      onClose={closeModal}
    />
  );
};

ServerErrorModalContainer.propTypes = propTypes;


const mapStateToProps = (state) => ({
  isOpen: getGenericModalState(state, SERVER_ERROR_MODAL_NAME),
});

const mapDispatchToProps = {
  closeModal: hideServerErrorModal,
};


export default connect(mapStateToProps, mapDispatchToProps)(ServerErrorModalContainer);
