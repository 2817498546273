import { combineLatestWith, filter, map, Observable, pipe, scan, UnaryFunction } from 'rxjs';

export function applyPauseWhenNotUnderEdit<T>(
  underEdit$: Observable<boolean>,
): UnaryFunction<Observable<T>, Observable<T>>;
export function applyPauseWhenNotUnderEdit<T>(
  underEdit$: Observable<boolean>,
  hasValue: (value?: T) => value is T,
  syntheticValue: T,
): UnaryFunction<Observable<T>, Observable<T>>;

export function applyPauseWhenNotUnderEdit<T>(
  underEdit$: Observable<boolean>,
  hasValue: (value?: T) => value is T = (value): value is T => value != null,
  syntheticValue?: T,
): UnaryFunction<Observable<T>, Observable<T>> {
  return pipe(
    combineLatestWith(underEdit$),
    scan<[T, boolean], [T | null, boolean]>(
      ([, last], [value, underEdit]) => {
        if (!underEdit && last !== underEdit && syntheticValue !== undefined) {
          return [hasValue(value) ? value : syntheticValue, underEdit];
        }

        return [value, underEdit];
      },
      [null, false],
    ),
    filter(([value, underEdit]) => value !== null && hasValue(value) && !underEdit),
    map(([value]) => value),
    filter((value): value is T => value !== null),
  );
}
