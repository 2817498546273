import React from 'react';

import { InfoTooltip, MiddleEllipsis } from '@neptune/shared/common-ui';
import { ProjectWithRole } from '@neptune/shared/core-project-domain';
import { ProjectAvatar, ProjectPrivacyEmblem } from '@neptune/shared/project-ui';
import { bemBlock, ButtonProps, Card, Layout, TabsMenu } from '@neptune/shared/venus-ui';

import './ProjectSettings.less';

const block = bemBlock('project-settings');

export type ProjectSettingsProps = {
  project: ProjectWithRole;
  tabs: { tabId: string; label: string; disabled?: boolean; disabledReason?: string }[];
  activeTabId?: string;
  onActiveTabChange: (tabId: string) => void;
  selectedOutlet?: React.ComponentType<{ project: ProjectWithRole }>;
};

export const ProjectSettings: React.FC<ProjectSettingsProps> = ({
  project,
  tabs,
  activeTabId,
  onActiveTabChange,
  selectedOutlet: SelectedOutlet,
}) => {
  return (
    <Layout.Column overflow="hidden" data-role="project-settings">
      <Layout.Column withPadding="md" spacedChildren="md" span="shrink">
        <Layout.Row alignItems="center" justifyContent="space-between">
          <Layout.Row span="auto">
            <ProjectAvatar radius="sm" size="xl" iconSize="lg" projectData={project} />
          </Layout.Row>
          <Layout.Row span="greedy" spacedChildren="md" overflow="hidden">
            <MiddleEllipsis expandable size="xl" text={project.name} />
            <Layout.Row span="auto">
              <ProjectPrivacyEmblem privacy={project.visibility} />
            </Layout.Row>
          </Layout.Row>
        </Layout.Row>
        <Layout.Row>
          <TabsMenu spacedChildren="lg">
            {tabs.map((tab, key) => {
              return (
                <InfoTooltip
                  show={tab.disabled}
                  placement="left-center"
                  text={tab.disabledReason}
                  key={key}
                >
                  <TabsMenu.Item<ButtonProps<{}>>
                    key={key}
                    data-item={tab.tabId}
                    onClick={() => onActiveTabChange(tab.tabId)}
                    selected={tab.tabId === activeTabId}
                    disabled={tab.disabled}
                  >
                    <span>{tab.label}</span>
                  </TabsMenu.Item>
                </InfoTooltip>
              );
            })}
          </TabsMenu>
        </Layout.Row>
      </Layout.Column>
      <Layout.Separator />
      <Layout.Column overflow="auto" withPadding="md" className={block('content')}>
        {SelectedOutlet && (
          <Card withBoxShadow={false} withPadding="md">
            <SelectedOutlet project={project} />
          </Card>
        )}
      </Layout.Column>
    </Layout.Column>
  );
};
