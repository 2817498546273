import { makeProjectIdentifier } from '@neptune/shared/core-project-util';
import { RouterSimpleState } from '@neptune/shared/routing-domain';

import { loadState, LocalStorageState, persistState } from 'common/localStorage';

export function persistLbViewId(routeState: RouterSimpleState) {
  const { organizationName, projectName, viewId } = routeState.params;

  if (viewId == null) {
    return;
  }

  const { lbViewIdByProject = {} } = loadState();
  const projectIdentifier = makeProjectIdentifier(organizationName, projectName);

  const newStateFragment = {
    lbViewIdByProject: {
      ...lbViewIdByProject,
      [projectIdentifier]: viewId,
    },
  };

  persistState(newStateFragment);
}

export function persistRunsDashboardTabMetadata(routeState: RouterSimpleState) {
  const { organizationName, projectName, tab, detailsTab, dashboardId } = routeState.params;

  if (tab !== 'details' || detailsTab == null) {
    return;
  }

  const lastExperimentDashboardTab = detailsTab;
  const state: Partial<LocalStorageState> = {
    lastExperimentDashboardTab,
  };

  if (lastExperimentDashboardTab === 'dashboard') {
    const { lastDashboardIds = {} } = loadState();
    const projectIdentifier = makeProjectIdentifier(organizationName, projectName);

    state.lastDashboardIds = {
      ...lastDashboardIds,
      [projectIdentifier]: dashboardId,
    };
  }

  persistState(state);
}

export function persistCompareDashboardTab(routeState: RouterSimpleState) {
  const { organizationName, projectName, tab, dash, dashboardId } = routeState.params;

  if (tab !== 'compare' || dash == null) {
    return;
  }

  const { compareTabByProject = {}, lastDashboardIds = {} } = loadState();
  const projectIdentifier = makeProjectIdentifier(organizationName, projectName);

  const compareTab = dash;

  const state: Partial<LocalStorageState> = {
    compareTabByProject: {
      ...compareTabByProject,
      [projectIdentifier]: compareTab,
    },
  };

  if (compareTab === 'dashboard') {
    state.lastDashboardIds = {
      ...lastDashboardIds,
      [projectIdentifier]: dashboardId,
    };
  }

  persistState(state);
}

export function persistProjectRunsTab(routeState: RouterSimpleState) {
  const { tab } = routeState.params;

  persistState({
    lastProjectRunsTab: tab,
  });
}
