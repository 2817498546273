import { Components } from 'react-markdown';

import { CodeRenderer } from './CodeRenderer';
import LinkRenderer from './LinkRenderer';
import TextRenderer from './TextRenderer';

const renderers: Components = {
  code: CodeRenderer,
  a: LinkRenderer,
  text: TextRenderer,
  pre: (({ children }) => children) as Components['pre'],
};

export { CodeRenderer, LinkRenderer, TextRenderer };
export default renderers;
