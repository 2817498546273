'use strict';

import { isObject } from 'lodash';
import urlParse from 'url-parse';
import baseConfig from './base';
import { customConfig } from './custom-config';

const dockerConfig = isObject(window.dockerConfig) ? compileDockerConfig(window.dockerConfig) : {};

const config = {
  appEnv: 'dist',
};

const featureFlags = {
  withAdminPanel: false,

  withSmoothingV2: true,

  withRunGroupsV4: false,

  withSingleRunAutoFollow: false,
  withSectionsInDashboards: false,
  withImprovedMultiRunSeriesVisibility: true,
  withDynamicRegexpMetrics: true,
  withMarkdownWidgetsDashboard: false,
  withNewDownloadAsPng: true,
};

export default Object.freeze(
  Object.assign(
    { releasedVersion: '' },
    baseConfig,
    config,
    featureFlags,
    dockerConfig,
    customConfig(),
  ),
);

function compileDockerConfig(config) {
  // in case of relative logoutURL, always convert to absolute as on-prems have problems when hosting from IPs
  const logoutURL = config.logoutURL
    ? urlParse(config.logoutURL).toString()
    : window.location.origin;

  const allowAddingNewLicense = !!config.allowAddingNewLicense;

  return Object.assign({}, config, {
    neptuneApiWSPort: config.neptuneApiPort,
    logoutURL,
    allowAddingNewLicense,
  });
}
