import { mapValues } from 'lodash';

import {
  GRID_LAYOUT_COLUMNS,
  LayoutDimensions,
  LayoutMinDimensions,
} from '@neptune/shared/grid-layout-domain';
import type { WidgetType } from '@neptune/shared/widgets-domain';

export type WidgetMinimumDimensionsTable = Partial<Record<WidgetType, LayoutMinDimensions>>;
export type WidgetDefaultDimensionsTable = Partial<Record<WidgetType, LayoutDimensions>>;

export const defaultWidgetMinDimensions: WidgetMinimumDimensionsTable = {
  chart: {
    minW: 1,
    minH: 4,
  },
  file: {
    minW: 1,
    minH: 4,
  },
  fileSet: {
    minW: 2,
    minH: 8,
  },
  gallery: {
    minW: 2,
    minH: 6,
  },
  image: {
    minW: 1,
    minH: 4,
  },
  imageComparison: {
    minW: 2,
    minH: 6,
  },
  interactiveTable: {
    minW: 2,
    minH: 6,
  },
  scatterPlot: {
    minW: 1,
    minH: 4,
  },
  table: {
    minW: 2,
    minH: 4,
  },
  valueList: {
    minW: 1,
    minH: 4,
  },
  section: {
    minW: GRID_LAYOUT_COLUMNS,
    minH: 1,
  },
  textNode: {
    minW: 2,
    minH: 4,
  },
};

const minDimensionsFallback = { minW: 1, minH: 2 };

export const defaultWidgetDimensions: WidgetDefaultDimensionsTable = {
  ...mapValues(defaultWidgetMinDimensions, (v) => v && { w: v.minW, h: v.minH }),
  chart: {
    w: 6,
    h: 6,
  },
  scatterPlot: {
    w: 6,
    h: 6,
  },
  imageComparison: {
    w: 6,
    h: 6,
  },
  singleValue: {
    w: 2,
    h: 4,
  },
  textNode: {
    w: 3,
    h: 6,
  },
};

const defaultDimensionsFallback = { w: 1, h: 2 };

export const defaultWidgetHeights: Partial<Record<WidgetType, number>> = mapValues(
  defaultWidgetDimensions,
  'h',
);

export function getWidgetMinimumDimensions(
  widgetType?: WidgetType,
  widgetMinDimensions = defaultWidgetMinDimensions,
): LayoutMinDimensions {
  return (widgetType && widgetMinDimensions[widgetType]) || minDimensionsFallback;
}

export function getWidgetDefaultDimensions(
  widgetType?: WidgetType,
  defaultDimensions = defaultWidgetDimensions,
): LayoutDimensions {
  return (widgetType && defaultDimensions[widgetType]) || defaultDimensionsFallback;
}

export function getDefaultWidgetHeight(
  widgetType?: WidgetType,
  // Allow overriding for tests.
  defaultHeights: Partial<Record<WidgetType, number>> = defaultWidgetHeights,
): number {
  return (widgetType && defaultHeights[widgetType]) ?? defaultDimensionsFallback.h;
}
