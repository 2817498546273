/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SingleTimeSeriesViewBucket } from './SingleTimeSeriesViewBucket';
import {
    SingleTimeSeriesViewBucketFromJSON,
    SingleTimeSeriesViewBucketFromJSONTyped,
    SingleTimeSeriesViewBucketToJSON,
} from './SingleTimeSeriesViewBucket';

/**
 * 
 * @export
 * @interface Point
 */
export interface Point {
    /**
     * 
     * @type {SingleTimeSeriesViewBucket}
     * @memberof Point
     */
    bucket?: SingleTimeSeriesViewBucket;
    /**
     * 
     * @type {boolean}
     * @memberof Point
     */
    interpolation: boolean;
    /**
     * 
     * @type {number}
     * @memberof Point
     */
    x: number;
    /**
     * 
     * @type {number}
     * @memberof Point
     */
    y?: number;
}

/**
 * Check if a given object implements the Point interface.
 */
export function instanceOfPoint(value: object): value is Point {
    if (!('interpolation' in value) || value['interpolation'] === undefined) return false;
    if (!('x' in value) || value['x'] === undefined) return false;
    return true;
}

export function PointFromJSON(json: any): Point {
    return PointFromJSONTyped(json, false);
}

export function PointFromJSONTyped(json: any, ignoreDiscriminator: boolean): Point {
    if (json == null) {
        return json;
    }
    return {
        
        'bucket': json['bucket'] == null ? undefined : SingleTimeSeriesViewBucketFromJSON(json['bucket']),
        'interpolation': json['interpolation'],
        'x': json['x'],
        'y': json['y'] == null ? undefined : json['y'],
    };
}

export function PointToJSON(value?: Point | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'bucket': SingleTimeSeriesViewBucketToJSON(value['bucket']),
        'interpolation': value['interpolation'],
        'x': value['x'],
        'y': value['y'],
    };
}

