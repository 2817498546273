import React from 'react';

import { SearchMode } from '@neptune/shared/search-domain';

type SearchContextType = {
  searchInputRef: React.RefObject<HTMLInputElement>;
};

const SearchContext = React.createContext<SearchContextType | undefined>(undefined);

export const useSearchContext = () => {
  const context = React.useContext(SearchContext);

  if (!context) {
    throw new Error('useSearchContext must be used within a SearchProvider');
  }

  return context;
};

export const SearchProvider: React.FC<{ mode: SearchMode }> = ({ mode, children }) => {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [prevMode, setPrevMode] = React.useState<SearchMode | null>(null);

  React.useEffect(() => {
    if (prevMode !== null && prevMode !== mode && inputRef.current) {
      inputRef.current.focus();
    }

    setPrevMode(mode);
  }, [mode, prevMode]);

  const contextValue = React.useMemo(() => ({ searchInputRef: inputRef }), []);

  return <SearchContext.Provider value={contextValue}>{children}</SearchContext.Provider>;
};
