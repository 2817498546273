import { LocalStorageCompatible } from './local-storage-compatible';
import { isLocalStorageV9 } from './local-storage-v9';
import { upgrade as upgradeFromPrevious } from './local-storage-v9.upgrade';
import { LocalStorageV10 } from './local-storage-v10';

/*
 * NOTE: Create new migration files only when you need to remove or edit existing field. Do not create new migration file for adding new field.
 */
export async function upgrade(raw: LocalStorageCompatible): Promise<LocalStorageV10> {
  const input = isLocalStorageV9(raw) ? raw : await upgradeFromPrevious(raw);
  const { data } = input;

  const { lastCompareDashboardIds, lastExperimentDashboardIds, ...rest } = data;

  const lastDashboardIds = { ...lastExperimentDashboardIds, ...lastCompareDashboardIds };

  return {
    version: 10,
    data: {
      ...rest,
      lastDashboardIds,
    },
  };
}
