import { useQueryBuilderKeyboardShortcuts } from './use-query-builder-keyboard-shortcuts';

export const useSearchShortcuts = (onSearchModeToggle: () => void) => {
  const { handleKeyUp, handleKeyDown } = useQueryBuilderKeyboardShortcuts({
    commandShortcuts: { '/': onSearchModeToggle },
  });

  return {
    handleShortcutOnKeyUp: handleKeyUp,
    handleShortcutOnKeyDown: handleKeyDown,
  };
};
