/* tslint:disable */
/* eslint-disable */
/**
 * Neptune Leaderboard REST API
 * Public REST API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: contact@neptune.ml
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DependencyOnInaccessibleProjectsErrorDTO
 */
export interface DependencyOnInaccessibleProjectsErrorDTO {
    /**
     * Error type.
     * @type {string}
     * @memberof DependencyOnInaccessibleProjectsErrorDTO
     */
    errorType: DependencyOnInaccessibleProjectsErrorDTOErrorTypeEnum;
    /**
     * Fully qualified names (`<workspace name>/<project name>`) of the projects inaccessible to the user calling the API.
     * @type {Array<string>}
     * @memberof DependencyOnInaccessibleProjectsErrorDTO
     */
    inaccessibleProjects: Array<string>;
    /**
     * Human-readable error message.
     * @type {string}
     * @memberof DependencyOnInaccessibleProjectsErrorDTO
     */
    message: string;
}


/**
 * @export
 */
export const DependencyOnInaccessibleProjectsErrorDTOErrorTypeEnum = {
    OrganizationNotFound: 'ORGANIZATION_NOT_FOUND',
    ProjectNotFound: 'PROJECT_NOT_FOUND',
    ProjectNotReady: 'PROJECT_NOT_READY',
    ExperimentNotFound: 'EXPERIMENT_NOT_FOUND',
    AttributesContainerNotFound: 'ATTRIBUTES_CONTAINER_NOT_FOUND',
    ChannelNotFound: 'CHANNEL_NOT_FOUND',
    LeaderboardEntryNotFound: 'LEADERBOARD_ENTRY_NOT_FOUND',
    ServiceUnavailable: 'SERVICE_UNAVAILABLE',
    InvalidOffset: 'INVALID_OFFSET',
    InvalidSortParams: 'INVALID_SORT_PARAMS',
    InvalidGroupingParams: 'INVALID_GROUPING_PARAMS',
    AccessDenied: 'ACCESS_DENIED',
    InternalServerError: 'INTERNAL_SERVER_ERROR',
    IncorrectIdentifier: 'INCORRECT_IDENTIFIER',
    BadRequest: 'BAD_REQUEST',
    Unauthorized: 'UNAUTHORIZED',
    FileNotFound: 'FILE_NOT_FOUND',
    NotebookNotFound: 'NOTEBOOK_NOT_FOUND',
    CheckpointNotFound: 'CHECKPOINT_NOT_FOUND',
    ViewNotFound: 'VIEW_NOT_FOUND',
    DashboardNotFound: 'DASHBOARD_NOT_FOUND',
    VersionBranchNotFound: 'VERSION_BRANCH_NOT_FOUND',
    CannotDeleteLastCheckpoint: 'CANNOT_DELETE_LAST_CHECKPOINT',
    RequestTimeout: 'REQUEST_TIMEOUT',
    DownloadRequestNotFound: 'DOWNLOAD_REQUEST_NOT_FOUND',
    ChannelAlreadyExists: 'CHANNEL_ALREADY_EXISTS',
    AttributePathNotFound: 'ATTRIBUTE_PATH_NOT_FOUND',
    InvalidAttributeType: 'INVALID_ATTRIBUTE_TYPE',
    MalformedJsonRequest: 'MALFORMED_JSON_REQUEST',
    TooManyRequestsPerUser: 'TOO_MANY_REQUESTS_PER_USER',
    TooManyOperations: 'TOO_MANY_OPERATIONS',
    ExperimentAlreadyExists: 'EXPERIMENT_ALREADY_EXISTS',
    Conflict: 'CONFLICT',
    WorkspaceInReadOnlyMode: 'WORKSPACE_IN_READ_ONLY_MODE',
    AttributesPerExperimentLimitExceeded: 'ATTRIBUTES_PER_EXPERIMENT_LIMIT_EXCEEDED',
    AuthorizationTokenExpired: 'AUTHORIZATION_TOKEN_EXPIRED',
    JwtValidationError: 'JWT_VALIDATION_ERROR',
    WriteAccessDeniedToArchivedProject: 'WRITE_ACCESS_DENIED_TO_ARCHIVED_PROJECT',
    ProjectLimitsExceeded: 'PROJECT_LIMITS_EXCEEDED',
    EsEntityContentTooLong: 'ES_ENTITY_CONTENT_TOO_LONG',
    ReportNotFound: 'REPORT_NOT_FOUND',
    ReportVersionNotFound: 'REPORT_VERSION_NOT_FOUND',
    ReportWithNoVersions: 'REPORT_WITH_NO_VERSIONS',
    ReportDraftNotFound: 'REPORT_DRAFT_NOT_FOUND',
    ReportVersionOwnerMismatch: 'REPORT_VERSION_OWNER_MISMATCH',
    DraftAlreadyExists: 'DRAFT_ALREADY_EXISTS',
    AttemptedConversionFromPublishedVersionToDraft: 'ATTEMPTED_CONVERSION_FROM_PUBLISHED_VERSION_TO_DRAFT',
    AttemptedChangeOfAVersionAuthor: 'ATTEMPTED_CHANGE_OF_A_VERSION_AUTHOR',
    AttemptedDeletionOfPublishedVersion: 'ATTEMPTED_DELETION_OF_PUBLISHED_VERSION',
    ReportPublishingConflict: 'REPORT_PUBLISHING_CONFLICT',
    DependencyOnInaccessibleProjects: 'DEPENDENCY_ON_INACCESSIBLE_PROJECTS',
    IngestSuspended: 'INGEST_SUSPENDED'
} as const;
export type DependencyOnInaccessibleProjectsErrorDTOErrorTypeEnum = typeof DependencyOnInaccessibleProjectsErrorDTOErrorTypeEnum[keyof typeof DependencyOnInaccessibleProjectsErrorDTOErrorTypeEnum];


/**
 * Check if a given object implements the DependencyOnInaccessibleProjectsErrorDTO interface.
 */
export function instanceOfDependencyOnInaccessibleProjectsErrorDTO(value: object): value is DependencyOnInaccessibleProjectsErrorDTO {
    if (!('errorType' in value) || value['errorType'] === undefined) return false;
    if (!('inaccessibleProjects' in value) || value['inaccessibleProjects'] === undefined) return false;
    if (!('message' in value) || value['message'] === undefined) return false;
    return true;
}

export function DependencyOnInaccessibleProjectsErrorDTOFromJSON(json: any): DependencyOnInaccessibleProjectsErrorDTO {
    return DependencyOnInaccessibleProjectsErrorDTOFromJSONTyped(json, false);
}

export function DependencyOnInaccessibleProjectsErrorDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): DependencyOnInaccessibleProjectsErrorDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'errorType': json['errorType'],
        'inaccessibleProjects': json['inaccessibleProjects'],
        'message': json['message'],
    };
}

export function DependencyOnInaccessibleProjectsErrorDTOToJSON(value?: DependencyOnInaccessibleProjectsErrorDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'errorType': value['errorType'],
        'inaccessibleProjects': value['inaccessibleProjects'],
        'message': value['message'],
    };
}

